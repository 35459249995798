
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { ExportService } from 'app/_services/export.service';
import { SharedServiceService } from 'app/_services/shared-service.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
declare var $: any;

declare var $: any;
@Component({
  selector: 'app-gift-card-management-component',
  templateUrl: './gift-card-management-component.component.html',
  styleUrls: ['./gift-card-management-component.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class GiftCardManagementComponentComponent implements OnInit {
  public loader: boolean = false;

  selectedInfoForView: any;
  isButtonEnable: boolean;
  pos: string;
  post: string;
  dynamicorderDetailsByCheckBox: any[];
  dict = {};
  selectedInfoForEdit: any;
  selectedAlldata = [];
  selectedAlldataArray = [];
  list: any;
  shareThisID: any;
  public moment: any = moment;
  deletedRes: any;
  downloadAndCancelArray = [];
  finalData: any;
  modifiedStatus: string;
  message_display: boolean;
  showThisScreenWithPermissions: any;
  allPermissionForOnlyThisScreen: any;
  permissionForOnlyThisScreen = [];
  custome_download: FormGroup;
  submitted: boolean;
  from_date: any;
  to_date: any;
  customeData: any;
  statustotalList: any;
  Store_status_List: any;
  pager: any = {};
  pagedItems: any;
  howmanySelectedArray = [];
  ShareThisSelectedList: number = 0;
  sortDir = 1; //1= 'ASE' -1= DSC
  offsetVal: number = 0;
  shareThisCount: any = 50;
  totalOrders: any;
  public isFliterActive: boolean = false;
  pageCount: { count: number; }[];
  initialLoad: boolean = true
  orderInput: any;
  emailInput: any;

  resetClicked = false;
  testing: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private exportService: ExportService,
    private service: SharedServiceService, private route: ActivatedRoute) {
    this.getStoresList("pageLoad");
    
    this.Store_status_List = [
      {
        "status": "Active",
        "value": "true"
      },
      {
        "status": "Inactive",
        "value": "false"
      }
    ]

    this.route.queryParams.subscribe(queryParams => {
     
      if (Object.keys(this.route.snapshot.queryParams).length == 0 && !this.initialLoad) {
        this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));
        this.getStoresList("pageLoad");
      }
    });

    if (Object.keys(this.route.snapshot.queryParams).length != 0 && this.initialLoad) {
      this.initialLoad = false;
      this.filterOrdersByWhenReload(this.route.snapshot.queryParams)
    } else {
      this.initialLoad = false;
      this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));
      
    }

    this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));
    

    for (var i = 0; i < this.showThisScreenWithPermissions.screens.length; i++) {
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "GiftCard") {
        var forOrders = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreen.push(forOrders);
      }
    }
    
    this.allPermissionForOnlyThisScreen = this.permissionForOnlyThisScreen[0];
    
    this.custome_download = this.formBuilder.group({
      from_date: [''],
      to_date: ['']
      
    });

    
  }
  formatDate(dateString: string): string[] {
    const [datePart, monthPart, yearPart, timePart] = dateString.split(' ');
    const formattedDate = `${datePart} ${monthPart} ${yearPart}`;
    const [hourPart, minutePart] = timePart.split(':');
    const hour = parseInt(hourPart);
    const minute = parseInt(minutePart);
    const amOrPm = hour >= 12 ? 'pm' : 'am';
    const formattedMinute = minute.toString().length === 1 ? `0${minute}` : minute;
    const formattedTime = `${hour % 12}:${formattedMinute} ${amOrPm}`;
    return [formattedDate, formattedTime];
  }

  get a() { return this.custome_download.controls; }

  public customeExport() {
    this.loader = true;
    this.submitted = true;
    if (this.custome_download.invalid) {
      this.loader = false;
      return;
    }
   

    this.from_date = new Date(this.a.from_date.value);
    this.to_date = new Date(this.a.to_date.value);
    
    if ((this.to_date - this.from_date) >= 0) {
      this.service.downloadCustomeStores(this.custome_download.value).subscribe(res => {
  
        if (res === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.customeData = res;
          
          this.exportService.exportExcel(this.customeData[0].stores, 'Store Export');
          this.loader = false;
          $('#from_date').val("");
          $('#to_date').val("");
          this.submitted = false;
          this.custome_download.reset();
        }
      }, err => {
        
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: err.error.error_desc
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      });
    } else {
      $('#to_date').val("");
      
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "To Date Should be Gater then From Date! Please currect the Date and try again"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
  }

  customeDownloads() {
    $('#from_date').val("");
    $('#to_date').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    this.custome_download.reset();
  }
  public customeSearch(offset = null, call = null) {
//     if (!this.orderInput && !this.emailInput &&  
//       !this.resetClicked) {
//    $.notify({
//      icon: "add_alert",
//      message:"Please Enter Input"
//    }, {
//      type: 'info',
//      timer: 1000,
//      placement: {
//        from: 'top',
//        align: 'center'
//      }
//    });
//  }

    this.isFliterActive = true;
    if (offset !== null) {
      this.offsetVal = offset
    }

    var request = {
      "reqBody": {

        "filter_params": [
          {
            "column_name": "order_no",
            "search": $('#order_filter').val() || null,
          },
          {
            "column_name": "email",
            "search": $('#email_filter').val() || null,
          },
          
        ]
      },
      "offset": this.offsetVal,
      "limit": this.shareThisCount

    }


    this.router.navigate(["/gift-card-management-component"], { queryParams: request.reqBody })
    this.service.searchgiftcard(request).subscribe(res => {
      this.testing=res
     
     
      if (res === null) {
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: "No Data Found"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      }
      else if (this.testing.message) {
        $.notify({
          icon: "add_alert",
          message: this.testing.message
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } 
       else {
        this.loader = false;
        this.pagedItems = res[0].giftcard;
        this.totalOrders = res[0].total_gc_count;


        

        
        this.setPage(1);
        
      }

    }, err => {
      
      this.loader = false;
    });
  }

  
  public reSet() {
    this.resetClicked = true;
    $('#store_name_filter').val("");
    $('#store_mobile_number').val("");
    $('#store_city').val("");
    $('#store_state').val("");
    $('#status_filter').val("");
    $('#email_filter').val("");
    $('#order_filter').val("");


    
    this.offsetVal = 0;
    this.shareThisCount = 50;
    this.isFliterActive = false;
    this.getStoresList("pageLoad");
    
  }
  enableThis1() {
    document.getElementById('store_name_filter').removeAttribute('readonly');
    $('#store_mobile_number').val("");
    $('#store_city').val("");
    $('#store_state').val("");
    $('#status_filter').val("");

    $("#store_mobile_number").attr("readonly", "true");
    $("#store_city").attr("readonly", "true");
    $("#store_state").attr("readonly", "true");
    

  }
  enableThis2() {
    document.getElementById('store_mobile_number').removeAttribute('readonly');
    $('#store_name_filter').val("");
    $('#store_city').val("");
    $('#store_state').val("");
    $('#status_filter').val("");

    $("#store_name_filter").attr("readonly", "true");
    $("#store_city").attr("readonly", "true");
    $("#store_state").attr("readonly", "true");
    

  }
  enableThis3() {
    document.getElementById('store_city').removeAttribute('readonly');
    $('#store_name_filter').val("");
    $('#store_mobile_number').val("");
    $('#store_state').val("");
    $('#status_filter').val("");

    $("#store_name_filter").attr("readonly", "true");
    $("#store_mobile_number").attr("readonly", "true");
    $("#store_state").attr("readonly", "true");
   

  }
  enableThis4() {
    document.getElementById('store_state').removeAttribute('readonly');
    $('#store_name_filter').val("");
    $('#store_mobile_number').val("");
    $('#store_city').val("");
    $('#status_filter').val("");

    $("#store_name_filter").attr("readonly", "true");
    $("#store_mobile_number").attr("readonly", "true");
    $("#store_city").attr("readonly", "true");
   
  }
  eraiseAll() {
    $('#store_name_filter').val("");
    $('#store_mobile_number').val("");
    $('#store_city').val("");
    $('#store_state').val("");
    $('#status_filter').val("");
    let closeDownload = document.getElementById("collapse_for_ciustomer");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }
  }
  public selectedStatusInfor(v) {
    
    $('#store_name_filter').val("");
    $('#store_mobile_number').val("");
    $('#store_city').val("");
    $('#store_state').val("");

    $("#store_name_filter").attr("readonly", "true");
    $("#store_mobile_number").attr("readonly", "true");
    $("#store_city").attr("readonly", "true");
    $("#store_state").attr("readonly", "true");

  }

  all(data) {
    this.selectedAlldata = data;
    
    if ($('#selectall').prop("checked") == true) {
      $('body').on('click', '#selectall', function () {
        $('.singlechkbox').prop('checked', this.checked);
      });
      
      this.ShareThisSelectedList = this.pagedItems.length;
    }
    else if ($('#selectall').prop("checked") == false) {
      
      this.selectedAlldata = [];
      this.ShareThisSelectedList = 0;
      
    }

  }
  // ----------------------------------------------//
  onSortClickSN(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrSN("store_name");
  }
  sortArrSN(colName: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  onSortClickCITY(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCITY("city");
  }
  sortArrCITY(colNameC: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colNameC].toLowerCase();
      b = b[colNameC].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  //-------------------------------------------------------// 
  generate() {
    
    if (this.ShareThisSelectedList > 0) {
      

      var finalDataModified = [];
      for (var i = 0; i < this.pagedItems.length; i++) {
        if ($('#dynamicID' + i).prop("checked") === true) {
          var use = {
            "Store Name": this.pagedItems[i].store_name,
            "DS Code": this.pagedItems[i].ds_code,
            "Plant Code": this.pagedItems[i].plant_code,
            "Phone Number": this.pagedItems[i].phone_no,
            "City": this.pagedItems[i].city,
            "State": this.pagedItems[i].state,
            "Country": this.pagedItems[i].country,
            "Is Active": this.pagedItems[i].is_active,
            "Line 1": this.pagedItems[i].line_1,
            "Line 2": this.pagedItems[i].line_2,
            "Street": this.pagedItems[i].street,
            "Pincode": this.pagedItems[i].pincode,
            "Lat Longs": this.pagedItems[i].lat_longs,
            "Serviceable Pincodes": this.pagedItems[i].serviceable_pincodes
          }
          finalDataModified.push(use);
        }
      }

      this.exportService.exportExcel(finalDataModified, 'Store Export');
      $('#selectall').prop("checked", false);
      this.getStoresList();
    } else {
      $.notify({
        icon: "add_alert",
        message: "Please select the List"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
  }
  single(event, data) {
    

    if (this.dict.hasOwnProperty(event)) {
      delete this.dict[event];
    } else {
      this.dict[event] = data;
    }
    this.selectedAlldata = [];
    for (var key in this.dict) {
      this.selectedAlldata.push(this.dict[key]);
    }
    


    $('body').on('click', '.singlechkbox', function () {
      if ($('.singlechkbox').length == $('.singlechkbox:checked').length) {
        $('#selectall').prop('checked', true);
        
      } else {
        $("#selectall").prop('checked', false);
        
      }
    });
    this.howmanySelectedArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        this.howmanySelectedArray.push(i);
      }
    }
    
    this.ShareThisSelectedList = this.howmanySelectedArray.length;

  }
  ngOnInit(): void {
    this.pageCount = [
      { "count": 10 },
      { "count": 20 },
      { "count": 50 },
      { "count": 100 },
      { "count": 500 }
    ]
  }
  getInputDateFormat(date) {
    return date.toISOString().split('T')[0];
  }

  validDate() {
    var today = new Date();
    var maxDate = new Date();

    document.getElementsByName("from_date_dd")[0].setAttribute('max', this.getInputDateFormat(today));
    document.getElementsByName('to_date_dd')[0].setAttribute('max', this.getInputDateFormat(maxDate));
  }
  public getStoresList(call = null) {
    this.loader = true;
    let requestBody = {
      'offset': this.offsetVal,
      'limit': this.shareThisCount
    }
    this.service.getAllCoupons(requestBody).subscribe(res => {
      
      this.list = res[0].giftcard;
      this.totalOrders = res[0].total_gc_count;
      this.pagedItems = this.list;
 
      if (this.pagedItems === null) {
        this.loader = false;
        this.message_display = true;
      } else {
        this.message_display = false;
        
        if (call === "pageLoad") {
          this.setPage(1);
        }
        this.loader = false;
      }
    }, err => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    })
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = this.shareThisCount) {
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPage(page: number, numClick = null) {
    this.ShareThisSelectedList = 0;
    $('#selectall').prop("checked", false);
    
    if (page < 1 || page > this.pager.totalPages) {
      
      return;
    }
    this.offsetVal = (page - 1) * this.shareThisCount;
   

    if (numClick !== null && this.isFliterActive == false) {
      this.getStoresList();
    } else if (numClick !== null && this.isFliterActive == true) {
      this.customeSearch();
    }
    this.pager = this.getPager(this.totalOrders, page);
   
    
  }
  public viewValue(Id) {
    this.selectedInfoForView = Id;
    this.router.navigate(['/store-view-details', Id]);
  }
  addNewCoupons() {
    this.router.navigate(['/create-new-gift-card']);
  }

  // public addNewStore() {
  //   this.router.navigate(['/add-new-store']);
  // }

  public forDelete(id) {
    this.shareThisID = id;
    
  }
  public deleteThisNow() {
    
    this.loader = true;
    this.service.deleteTheSelectedStore(this.shareThisID).subscribe(res => {
      
      this.deletedRes = res;
      $("#remove_selecte_store").modal("hide");
      this.getStoresList();
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: this.deletedRes.message
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }, err => {
      
      this.loader = false;
    });
  }

  public closethisNow() {
    
    $("#profile_edit_verified").trigger("click")
  }

  countPerPage(count) {
   // console.log(count);
    this.shareThisCount = count;
    this.offsetVal = 0;
    this.isFliterActive = false;
    $('#selectall').prop("checked", false);
    
    this.setPage(1);
  }

  filterOrdersByWhenReload(body) {
    var request = {
      "reqBody": body,
      "offset": this.offsetVal,
      "limit": this.shareThisCount
    }
    setTimeout(() => {
      document.getElementById('heading2').click()
      let ele: any = document.getElementById('status_filter')
      ele.value = body.search
    }, 500);
    this.service.searchgiftcard(request).subscribe(res => {
  
      if (res === null) {
        this.loader = false;
       
        $('#email_filter').val("");
        $('#order_filter').val("");
        $.notify({
          icon: "add_alert",
          message: "No Data Found"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        this.loader = false;
        this.pagedItems = res[0].giftcard;
        this.totalOrders = res[0].total_gc_count;

       
        this.setPage(1);
      }
    }, err => {
      
      this.loader = false;
    });
  }

}
