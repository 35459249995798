import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../app/pipes/pipes.module';
import { DatePipe } from '@angular/common'
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { MatChipsModule } from '@angular/material/chips';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReturnOrderComponentComponent } from './return-order-component/return-order-component.component';
import { ViewReturnOrdersComponent } from './view-return-orders/view-return-orders.component';
import { ShopifyStrideRiteComponent } from './shopify-stride-rite/shopify-stride-rite.component';
import { ShopifyAndreAssousComponent } from './shopify-andre-assous/shopify-andre-assous.component';
import { WallmartComponent } from './wallmart/wallmart.component';
import { EbayComponent } from './ebay/ebay.component';
import { GiftCardManagementComponentComponent } from './gift-card-management-component/gift-card-management-component.component';
import { CreateNewGiftCardComponent } from './create-new-gift-card/create-new-gift-card.component';
import { ProductCatalogComponent } from './product-catalog/product-catalog.component';
import { NewinventoryComponent } from './newinventory/newinventory.component';
import { CancelordersComponent } from './cancelorders/cancelorders.component';
import { CatalogviewComponent } from './catalogview/catalogview.component';
import { InitiatereturnComponent } from './initiatereturn/initiatereturn.component';
import { StrideRiteInitiateReturnComponent } from './stride-rite-initiate-return/stride-rite-initiate-return.component';
import { AndreAssousInitiateReturnComponent } from './andre-assous-initiate-return/andre-assous-initiate-return.component';

import { ReturnandreplacementComponent } from './returnandreplacement/returnandreplacement.component';
import { TokenInterceptorService } from './_services/token-interceptor.service';
import { ShopifyTargetComponent } from './shopify-target/shopify-target.component';
import { CreateappeasementComponent } from './createappeasement/createappeasement.component';
import { ImgDirective } from './img.directive';

import { TwoDigitDecimaNumberDirective } from './twodigitdecimalnumber.directive';
// import { ViewOrderPageComponent } from './view-order-page/view-order-page.component';
// import { ReturnwithoutlabelComponent } from './returnwithoutlabel/returnwithoutlabel.component'

  
// import { GroupByChannelIdComponent } from './group-by-channel-id/group-by-channel-id.component';

// import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    // NgxPaginationModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    PipesModule,
    HttpClientModule,
    AppRoutingModule,
    MatChipsModule,
    MatSlideToggleModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    // WmsOdersComponent,
    ReturnOrderComponentComponent,
    ViewReturnOrdersComponent,
    ShopifyStrideRiteComponent,
    ShopifyAndreAssousComponent,
    WallmartComponent,
    EbayComponent,
    GiftCardManagementComponentComponent,
    CreateNewGiftCardComponent,
    ProductCatalogComponent,
   
    GiftCardManagementComponentComponent,
    CreateNewGiftCardComponent,
    ProductCatalogComponent,
    NewinventoryComponent,
    CancelordersComponent,
    CatalogviewComponent,
    InitiatereturnComponent,
    StrideRiteInitiateReturnComponent,
    AndreAssousInitiateReturnComponent,

    ReturnandreplacementComponent,

    ShopifyTargetComponent,

    CreateappeasementComponent,
    TwoDigitDecimaNumberDirective,
    ImgDirective,
    // ViewOrderPageComponent,
    // ReturnwithoutlabelComponent,

    

    

   
  ],
  providers: [DatePipe, {
    provide:HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
