import {
  Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef,
  HostListener, ElementRef, ChangeDetectionStrategy
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharedServiceService } from 'app/_services/shared-service.service';
import { from } from 'rxjs';
import { data } from 'jquery';
import * as moment from 'moment';
import { replaceAll } from 'chartist';
import { Location } from '@angular/common';

declare var $: any;




@Component({
  selector: 'app-createappeasement',
  templateUrl: './createappeasement.component.html',
  styleUrls: ['./createappeasement.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateappeasementComponent implements OnInit {

  buttonHover = ""
  filterOrders = "";
  isButtonEnable: boolean;
  pos: string;
  post: string;
  dynamicorderDetailsByCheckBox: any[];
  dict = {};
  selectedInfoForView: any;
  selectedInfoForEdit: any;
  finalData: { order_id: string; order_date: string; delivery_date: string; customer_name: string; payment_status: string; order_type: string; status: string; }[];
  selectedAlldata = [];
  selectedAlldataArray = [];
  allOrdersList: any;
  loader: boolean;
  message_display: boolean = false;
  showThisScreenWithPermissions: any;
  permissionForOnlyThisScreen = [];
  allPermissionForOnlyThisScreen: any;
  statustotalList: any;
  custome_download: FormGroup;
  productBulkRemoval: FormGroup;
  custome_search: FormGroup;
  submitted: boolean;
  from_date: any;
  to_date: any;
  customeData: any;
  pageOfItems: any;
  first_id: any;
  last_id: any;
  totalElements: any;
  forFilterUse: any;
  fullfillData: any;
  config: any;
  id: any;
  pager: any = {};
  pagedItems = [];
  finalStatus: any;
  status_with_time: any;
  statusTimeStampArray = [];
  finalArray: any;
  modifiedStatusArray: any;
  statusArray = [];
  updateMesg: any;
  reqstbdy: any;
  NewStatusArray = [];
  hidethisStatus: boolean;
  NewStatusArray_final = [];
  statusArray_main_one_userMatrix = [];
  currectArray = [];
  statusArray_main_one_userMatrix_include_key = [];
  thisIsMatched: boolean;
  arraychecking = [];
  sendThis_item_s: any;
  sendThis_selected_previous_status: any;
  showThisSelectMSG: boolean;
  showOnlyForCancel: boolean;
  showThisMsgReasonRequired: boolean;
  pageCount = [];
  howmanySelectedArray = [];
  ShareThisSelectedList: number = 0;
  onlyForDashboard: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  testingNow: any;
  shareThisCount: any = 20;
  offsetVal: number = 0;
  totalOrders: any;
  public isFliterActive: boolean = false;
  public bulk_update_waiting: boolean = false;
  searchkey: any
  isSubmit: boolean;
  checkNowAll: any;
  walletRevalsal: any;
  public moment: any = moment;
  newUser: any;
  user: string;
  initialLoad: boolean = true
  deliveryDate: any;
  testing: Object;
  delivery = ('#delivery_date'.replace('-', '/'));
  buzzerStatus: any;
  storeCodes: any;
  storedataresponse: any;
  storeCodeDataNew: any;
  allStoresIds: any;
  addingNewiteminOrder: any;
  selectedOMSIDorderID: any;
  selectedOMSIDorderIDfinal: any;
  fromGBCIDInfo: any;
  fromGBCID: any;
  returnOrderIdd: any;
  moveToAllOrdersList: boolean = false;
  // orderzipCode: any;
  orderEmail: any;
  totalreasons: any;
  itemsData: any;
  Customerbillingaddresses: any;
  Customershippingaddresses: any;
  returnorderstatus: boolean = false;
  shipmentoptionsData: any;
  shipmentOptionsInfo: any;
  selectedShipperName: any;
  shipperServiceData: any;
  shipperServiceInfo: any;
  selectedShipperServiceData: any;
  selectedReturenReason: any;
  selectedOrderDetails: any;
  returnAndreplacementData: boolean;
  returnAndrefundData: boolean;
  proceedToInitiatedReturn: boolean;
  showThis45DaysError: any;
  showthisitemafterclick: boolean = true;
  returnTypeInfo: any;
  selectedReturnTypeInfo: any;
  reason_line_itemid: any;
  maximum_amount: any;
  amount: any;
  transactions: any;
  GCamount: number;
  giftCardRefunds: any;
  shopifyPaymentsRefunds: any;
  updatedTransactions: any;
  shopifyPayment_gcmaximum_refundable: any
  gcmaxrefund: any;
  amounts: any;
  hideThisShipperShippingInfoBlock: boolean;
  Reqgcrefund: any;
  ReqAmount: number;
  orderparts: any;
  apeasementstatus: any;
  gcresult: any;
  finalReqAmount: number;
  finalgcAmount: number;
  errormessage_refund: any;
  showFields: boolean;
  req: any;
  isSubmitDisabled = false;

  selectedValue: string;

  selectedOption = 'default';
  selectedOptionAppeasementType = 'default';
  selectedOptionAppeasementSub = 'default';
  hideTheCheckBoxDefault: boolean = true;
  activeApplyDiscount: boolean = false;

  activeAppeasement: boolean = false;
  activateTheSingle: boolean = false;
  hideTheSingleCheckBoxDefault: boolean = true;
  inputValue = '';
  inputValuee = ''
  inputValueLineItemChange = '';
  appeasement_maximum_amount: any;
  allChecked: boolean;
  full_refund_amount: any;
  S_and_H: any;
  selectAllChecked: boolean = false;
  refund_amount: boolean = false;
  showIncludeTaxDiv = false;
  showIncludeTax = false;
  setTheDefaultView: boolean = false;
  appeasementResultSelectedCheckbox: any;
  showTheEnterCustomeAmount: boolean = false;
  allcheckboxes = [];
  pleaseEnterInputValue: boolean;
  shippedStatusCount = [];
  presentCheckBoxIndex = [];
  allcheckboxesNew = [];
  finalSandHValue: any = 0;
  showcasetheGCvalueblock: boolean = false;
  singlecheckboxSelectedItem: any;
  selectedGCvalue: any;
  shopifyamountselectedvalue: any;
  shopifygiftCartamountSum: any;
  showcasetheonlyGCvalueblock: boolean = false;
  GCamountForPrice: any;
  taxCheckBoxChecked: boolean;
  taxAmount: any = 0;
  sandhCheckBoxChecked: boolean = false;
  logInUser: any;
  activeApplyDiscountValue: number = 0 ;
  applyDiscountResponse: any;
  selectedAppeasement: boolean = false;
  selectedSandHAppeasmentRes: any;
  activeAppeasementSub: boolean = false;
  selectedItemTaxAppeasmentRes: any;
  activeAppeasementSubItemTax: boolean = false;
  selectedAfterAppeasement: any;
  activeAppeasementSubLineItem: boolean = false;
  selectedLineItemAppeasmentRes: any;
  showThisAppeasementDropDown: boolean = false;
  selectedOrderPartNumberDetails: any;
  selectedForOrderAppeasementType: any;
  getSelectedOrder_part_noForAppeasment: any;
  shareThisForlineItemValue: any;
  taxSelectedForLintItem: boolean = false;
  taxSelectedForLineItem: any;
  gettransactions: any;
  selectedTransactionDetails: any = null;
  selectedForDiscount: any;
  inputValueonLineItemChange: string = '';
  hideTheFinalSubmitButton: boolean = true;
  activeApplyDiscountTwo: boolean = false;
  showThisSelectedTransactions: string = null;
  showThisMessageNow: boolean = false;
  finalresult: any;
  itemtaxCheckboxChecked: boolean = false;
  selectedItemTaxAppeasmentResFinal: any;
  showHideTheTitel: boolean;
  showTheErrorMessage: boolean = false;
  permissionInfo: any;
  paramsForAppesementInfo: any;
  justSelectedSandH: boolean = false;

  constructor(private ref: ChangeDetectorRef, private router: Router, private formBuilder: FormBuilder,
    private exportService: ExportService, private service: SharedServiceService, public sharedService: SharedServiceService,
    private detailsData: ExportService, private route: ActivatedRoute, private location: Location) {




    this.paramsForAppesementInfo = JSON.parse(localStorage.getItem('paramsForAppesement'));


    // this.route.queryParams.subscribe(res => {
    // console.log(res);
    this.returnOrderIdd = this.paramsForAppesementInfo.OrderNo;
    this.orderEmail = this.paramsForAppesementInfo.Email;
    this.permissionInfo = this.paramsForAppesementInfo.permission;
    // this.orderzipCode = res.Pincode;
    this.checkorderstatus();

    // })


    this.fromGBCID = this.route.params.subscribe(params => {
      // console.log(params);
      this.fromGBCIDInfo = params;
      if (this.fromGBCIDInfo.order_no) {
        // console.log(true);
        this.getOnlyThisOrder("pageLoad", this.fromGBCIDInfo.order_no);
        this.moveToAllOrdersList = true;
      } else {

        this.route.queryParams.subscribe(queryParams => {

          if (Object.keys(this.route.snapshot.queryParams).length == 0 && !this.initialLoad) {

          }
        });
        if (Object.keys(this.route.snapshot.queryParams).length != 0 && this.initialLoad) {
          this.initialLoad = false;

        } else {
          this.initialLoad = false;

        }

      }
    });

    // this.location.replaceState(this.location.path().split('?')[0]);

    this.onlyForDashboard = localStorage.getItem('dashboard');
    // console.log(this.onlyForDashboard);
    this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));


    for (var i = 0; i < this.showThisScreenWithPermissions.screens.length; i++) {
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Orders") {
        var forOrders = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreen.push(forOrders);
      }
    }
    ;


    this.allPermissionForOnlyThisScreen = this.permissionForOnlyThisScreen[0];
    // console.log(this.allPermissionForOnlyThisScreen);

    this.custome_download = this.formBuilder.group({
      from_date: [''],
      to_date: [''],
      status_id: [0]
    });

    this.productBulkRemoval = this.formBuilder.group({
      skuCode: ['', Validators.required],
      storeSelect: ['default', Validators.required],
      status: ['', Validators.required],
      reason: ['', Validators.required],
      quantity: ['']


    });


    this.custome_search = this.formBuilder.group({
      order_id: [''],
      order_date: [''],
      customer_name: [''],
      payment_type: [''],
      order_status: [''],
      mobile_number: [''],
      delivery_date: [''],

    });
  }





  get a() { return this.custome_download.controls; }
  get b() { return this.custome_search.controls; }

  public selectedStatusInfor(v) {

    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#mobile_number').val("");
    $('#delivery_date').val("");



  }
  public customeExport() {
    this.loader = true;
    this.submitted = true;
    if (this.custome_download.invalid) {
      this.loader = false;
      return;
    }


    this.from_date = new Date(this.a.from_date.value);
    this.to_date = new Date(this.a.to_date.value);

    if ((this.to_date - this.from_date) >= 0) {
      // console.log(this.custome_download.value);
      this.service.downloadCustomeorders(this.custome_download.value).subscribe(res => {
        // console.log(res);
        if (res === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.customeData = res;

          let date = "All order";
          if (this.a.from_date.value && this.a.to_date.value) {
            date = `Order Export - ${moment(this.a.from_date.value).format("DD/MM/YYYY")} -${moment(this.a.to_date.value).format("DD/MM/YYYY")}`;
          }
          this.exportService.exportExcel(this.customeData[0].orderfilter, date);
          this.loader = false;
          $('#from_date').val("");
          $('#to_date').val("");
          $('#selectedStatus').val("");
          this.submitted = false;
          this.custome_download.reset();
        }
      }, err => {

        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: err.error.error_desc
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      });
    } else {
      $('#to_date').val("");

      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "To Date Should be Gater then From Date! Please currect the Date and try again"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }

  }
  customeDownloads() {
    $('#from_date').val("");
    $('#to_date').val("");
    $('#selectedStatus').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    this.custome_download.reset();
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }
  bulkProduct() {
    $('#sku').val("");
    $('#store_code').val("");
    $('#status').val("");
    $('#reason').val("");
    $('#quantity').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }

  }

  public reSet() {
    this.deliveryDate = '';
    $('#order_id_filter').val("");

    $('#chanenl_with_filter').val("");
    $('#customer_mail_id_filter').val("");
    $('#customer_name_id_filter').val("");
    $('#order_date').val("");

    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");
    $('#mobile_number').val("");




    this.offsetVal = 0;
    this.shareThisCount = 20;
    this.isFliterActive = false;

    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }

  }
  enableThis1() {
    document.getElementById('order_id_filter').removeAttribute('readonly');
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");


  }
  enableThis2() {
    document.getElementById('order_date').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivary_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");


  }
  enableThis3() {
    document.getElementById('customer_name').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");


  }
  enableThis4() {
    document.getElementById('payment_type').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");


  }


  eraiseAll() {
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#store_code').val("");
    $('#delivery_date').val("");

    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }



  all(data) {

    // console.log(data);
    this.selectedAlldata = data;
    // console.log(this.selectedAlldata);
    if ($('#selectall').prop("checked") == true) {
      $('body').on('click', '#selectall', function () {
        $('.singlechkbox').prop('checked', this.checked);
      });
      // console.log("Checkbox is checked.");
      this.ShareThisSelectedList = this.pagedItems.length;
    }
    else if ($('#selectall').prop("checked") == false) {
      // console.log("Checkbox is unchecked.");
      this.selectedAlldata = [];
      this.ShareThisSelectedList = 0;

    }
  }
  generate() {
    // console.log(this.ShareThisSelectedList);
    if (this.ShareThisSelectedList > 0) {
      // console.log(this.selectedAlldata);

      var finalDataModified = [];
      for (var i = 0; i < this.pagedItems.length; i++) {
        if ($('#dynamicID' + i).prop("checked") === true) {
          var use = {
            "Order No": this.pagedItems[i].order_no,
            "Created At": this.pagedItems[i].created_at,
            "First Name": this.pagedItems[i].first_name,
            "Last Name": this.pagedItems[i].last_name,
            "Payment Method": this.pagedItems[i].payment_method,
            "Status": this.pagedItems[i].status
          }
          finalDataModified.push(use);
        }
      }
      this.exportService.exportExcel(finalDataModified, 'Order Export');
      $('#selectall').prop("checked", false);

    } else {
      $.notify({
        icon: "add_alert",
        message: "Please select the List"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
  }
  single(event, data) {
    // console.log(data);
    if (this.dict.hasOwnProperty(event)) {
      delete this.dict[event];
    } else {
      this.dict[event] = data;
    }
    this.selectedAlldata = [];
    for (var key in this.dict) {
      this.selectedAlldata.push(this.dict[key]);
    }
    // console.log(this.selectedAlldata);

    $('body').on('click', '.singlechkbox', function () {
      if ($('.singlechkbox').length == $('.singlechkbox:checked').length) {
        $('#selectall').prop('checked', true);

      } else {
        $("#selectall").prop('checked', false);

      }
    });

    this.howmanySelectedArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        this.howmanySelectedArray.push(i);
      }
    }
    // console.log(this.howmanySelectedArray.length);
    this.ShareThisSelectedList = this.howmanySelectedArray.length;

  }
  getInputDateFormat(date) {
    return date.toISOString().split('T')[0];
  }

  validDate() {
    var today = new Date();
    var maxDate = new Date();

    document.getElementsByName("from_date_dd")[0].setAttribute('max', this.getInputDateFormat(today));
    document.getElementsByName('to_date_dd')[0].setAttribute('max', this.getInputDateFormat(maxDate));
  }


  ngOnInit(): void {

    this.logInUser = localStorage.getItem('role');
    // console.log(this.logInUser);
    this.returnTypeInfo = [
      {
        type: "Return & Refund"
      },
      { type: "Return & Replacement" }
    ]


    this.detailsData.viewDetails.subscribe(user => {
      // console.log("user", user);

    });



    this.pageCount = [
      { "count": 10 },
      { "count": 20 },
      { "count": 50 },
      { "count": 100 },
      { "count": 500 },

    ]


  }



  editedUser(user: string) {
    this.detailsData.editUser(this.newUser);
  }


  previuosOrderCount: any;
  public getOnlyThisOrder(call = null, id) {
    this.loader = true;
    // console.log(localStorage.getItem('forCRMTEAM'));

    let requestBody = {
      'user_id': localStorage.getItem('role_id'),
      'offset': this.offsetVal,
      'limit': this.shareThisCount,
      'is_payment_failed': false
    }
    // console.log(requestBody);


    this.service.getOrdersByID(requestBody).subscribe(res => {
      // console.log(res);



      if (res === null) {
        this.loader = false;
        this.message_display = true;

      } else {
        this.loader = false;
        this.message_display = false;


        this.allOrdersList = res[0].orders;
        // console.log(this.allOrdersList);
        this.addingNewiteminOrder = res[0].orders;

        var finalData = _.map(this.addingNewiteminOrder, function (item) {
          if (item.order_no == id) {
            return item;
          }

        })
        finalData = finalData.filter((item) => {
          return item != null;
        });
        // console.log(finalData);

        // console.log(this.addingNewiteminOrder);

        this.checkNowAll = finalData;

        this.totalOrders = res[0].total_order_count
        this.allOrdersList = this.checkNowAll.filter(obj => obj.status != "payment_failed");
        this.pagedItems = this.allOrdersList.slice(0);
        if (call === "pageLoad") {
          this.setPage(1);
        }

      }
    }, err => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });

  }


  countPerPage(count) {
    // console.log(count);
    this.shareThisCount = count;
    this.offsetVal = 0;
    this.isFliterActive = false;
    $('#selectall').prop("checked", false);

    this.setPage(1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = this.shareThisCount) {

    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    // this.loader = false;
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPage(page: number, numClick: string = null) {
    this.ShareThisSelectedList = 0;
    $('#selectall').prop("checked", false);

    if (page < 1 || page > this.pager.totalPages) {
      this.loader = false;
      return;
    }
    this.offsetVal = (page - 1) * this.shareThisCount;

    if (numClick !== null && this.isFliterActive == false) {

    } else if (numClick !== null && this.isFliterActive == true) {

    }
    this.pager = this.getPager(this.totalOrders, page);



  }

  // ----------------------------------------------//
  onSortClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr("status");
  }

  onSortClick1(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr1("created_at");
  }

  onSortClickOID(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrOID("order_no");
  }
  onSortClickCN(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCusN("first_name", "last_name");



  }
  onSortClickPT(event) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCN("payment_method");



  }

  sortArr(colName: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  sortArr1(colName1: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName1].toLowerCase();
      b = b[colName1].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrOID(colName2: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName2].toLowerCase();
      b = b[colName2].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCN(colName3: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName3].toLowerCase();
      b = b[colName3].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCusN(colName3: any, x: any) {
    this.pagedItems.sort((a, b) => {
      a = (a[colName3] + ' ' + a[x]).toLowerCase();
      b = (b[colName3] + ' ' + b[x]).toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  // --------------------------------------------//




  check_info_date(d) {


  }

  check_info_payment_type(p) {


  }
  check_info_status(s) {


  }


  public viewValue(Id) {
    // console.log("Selected Order", Id);
    this.selectedInfoForView = Id;
    this.router.navigate(['/view-orders-page', Id]);

  }

  bulkStatus(Index_id, item_s, selected_previous_status) {
    // console.log(item_s);
    // console.log(this.allOrdersList);
    // console.log(this.statusArray);
    // console.log(this.statusArray_main_one_userMatrix);
    this.sendThis_item_s = item_s;
    this.sendThis_selected_previous_status = selected_previous_status;

    if (item_s.status_name === "cancelled") {
      this.showOnlyForCancel = true;
    } else {
      this.showOnlyForCancel = false;
    }

  }
  reasonClose() {
    this.showThisMsgReasonRequired = false;
    $('#cancelation_reason').val("");
  }
  bulkStatusUpdate() {
    // console.log(this.ShareThisSelectedList);
    $("#bulk_status_confirmation_open").modal("hide");
    if (this.sendThis_item_s.status_name === "placed") {
      var status = "Placed";
    }
    if (this.sendThis_item_s.status_name === "confirmed") {
      var status = "Confirmed";
    }
    if (this.sendThis_item_s.status_name === "pickup_confirmed") {
      var status = "Pickup Confirmed";
    }
    if (this.sendThis_item_s.status_name === "in_process") {
      var status = "In Process";
    }
    if (this.sendThis_item_s.status_name === "ready_to_dispatch") {
      var status = "Ready To Dispatch";
    }
    if (this.sendThis_item_s.status_name === "out_for_delivery") {
      var status = "Out For Delivery";
    }
    if (this.sendThis_item_s.status_name === "delivered") {
      var status = "Delivered";
    }
    if (this.sendThis_item_s.status_name === "cancelled") {
      var status = "Cancelled";
    }
    // console.log(this.sendThis_selected_previous_status);
    // // console.log(this.selectedAlldata);
    // console.log(this.pagedItems);
    var orderISsArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        if (this.pagedItems[i].status === this.sendThis_selected_previous_status) {
          orderISsArray.push(this.pagedItems[i].order_id);
        }
      }
    }
    // console.log(orderISsArray);



    // console.log("access", this.statusArray_main_one_userMatrix);

    // console.log(this.sendThis_item_s.status_id);


    if (this.sendThis_item_s.status_name === "cancelled") {
      var cancelationReason = $('#cancelation_reason').val();
      if (cancelationReason === "" || cancelationReason === undefined || cancelationReason === null) {
        this.showThisMsgReasonRequired = true;
      } else {
        this.showThisMsgReasonRequired = false;
        // console.log("You can");
        var NeworderISsArray = [];
        var NeworderISsArrayNew = [];
        var camparingArray = new Array();
        camparingArray[0] = "cancelled",
          camparingArray[1] = "out_for_delivery",
          camparingArray[2] = "delivered",
          camparingArray[3] = "payment_failed"
        // console.log(camparingArray);
        for (var i = 0; i < this.pagedItems.length; i++) {
          if ($('#dynamicID' + i).prop("checked") === true) {
            var n = camparingArray.includes(this.pagedItems[i].status);
            if (n) {
              NeworderISsArrayNew.push(this.pagedItems[i].order_id);
            } else {

              NeworderISsArray.push(this.pagedItems[i].order_id);
            }
          }
        }
        // console.log(NeworderISsArray);
        if (NeworderISsArray.length === 0) {

          $.notify({
            icon: "add_alert",
            message: "You Can't change Into" + " " + status + " " + "Status"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          this.loader = false;
          this.selectedAlldata = [];

          NeworderISsArray = [];
          this.arraychecking = [];
          // console.log(this.selectedAlldata);
        } else {
          var cancelationReason = $('#cancelation_reason').val();
          // console.log(cancelationReason);

          // console.log(cancelationReason);
          // console.log(NeworderISsArray);
          var reqCancel = {
            "status_id": this.sendThis_item_s.status_id,
            "order_id": NeworderISsArray,
            "reason": cancelationReason
          }
          // console.log(reqCancel);
          this.loader = true;
          this.bulk_update_waiting = true;
          this.service.updateBulk(reqCancel).then(async res => {
            // console.log(res);
            this.updateMesg = res;
            await this.updateMesg;
            $('#cancelation_reason').val("");

            $('#selectall').prop("checked", false);
            this.bulk_update_waiting = false;
            this.loader = false;
            $.notify({
              icon: "add_alert",
              message: this.updateMesg.message
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];

            NeworderISsArray = [];
            this.arraychecking = [];
            // console.log(this.selectedAlldata);
            this.showThisMsgReasonRequired = false;

          }).catch(err => {
            // console.log(err);
            this.selectedAlldata = [];
            NeworderISsArray = [];
            // console.log(this.selectedAlldata);
            this.loader = false;

            $('#selectall').prop("checked", false);
            $.notify({
              icon: "add_alert",
              message: err.error.error_desc
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];
            this.arraychecking = [];
          });
        }
      }
    } else {
      if (orderISsArray.length === 0) {
        $.notify({
          icon: "add_alert",
          message: "You Can't change Into" + " " + status + " " + "Status"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        this.loader = false;
        this.selectedAlldata = [];

        NeworderISsArray = [];
        this.arraychecking = [];
        // console.log(this.selectedAlldata);

      } else {

        var req = {
          "status_id": this.sendThis_item_s.status_id,
          "order_id": orderISsArray,
          "reason": null
        }
        // console.log(req);
        this.loader = true;
        this.bulk_update_waiting = true;
        this.service.updateBulk(req).then(res => {
          // console.log(res);
          this.updateMesg = res;

          $('#selectall').prop("checked", false);
          this.bulk_update_waiting = false;
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.updateMesg.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          NeworderISsArray = [];
          this.selectedAlldata = [];
          this.arraychecking = [];
          // console.log(this.selectedAlldata);

        }).catch(err => {
          // console.log(err);
          NeworderISsArray = [];
          this.selectedAlldata = [];

          this.arraychecking = [];
          // console.log(this.selectedAlldata);

          $('#selectall').prop("checked", false);
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: err.error.error_desc
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });

      }
    }
  }

  closethisNow() {

    $("#bulk_status_confirmation_close").trigger("click");
  }



  onPrintInvoice(item) {
    // console.log(item);
    this.service.getInvoiceData(item.order_id).subscribe(res => {
      // console.log(res);
      this.testingNow = res;
      this.ref.detectChanges();
      var dataInfo = document.getElementById('elem');
      const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(dataInfo.innerHTML); WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();

    }, err => {
      // console.log(err);
      window.close();
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
  }

  handleAsideClick(event: Event) {
    event.stopPropagation();
  }
  isSearchSuggestion: boolean;
  searchedProducts: any;
  searchLoader: boolean;
  search(searchText, event) {
    this.loader = true;
    if (event.keyCode == 13) {
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    if (searchText.length < 3) {
      this.searchedProducts = '';
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    this.searchLoader = true;


    let requestBody = {
      "param": {
        "type": "search",//search or category
        "productName": searchText,// only if search

        "store_id": localStorage.getItem('plant_code_add_product'),
        "categoryId": "",
        "sort_by": '',
        "pageNumber": "",
        "pageCount": "",
        "filters": {
        }
      }
    }
    // console.log(requestBody);
    this.sharedService.productSearch(requestBody).subscribe(data => {
      // console.log(data);
      this.searchLoader = false;
      this.searchedProducts = data[0].products;
      // console.log(this.searchedProducts);
      this.isSearchSuggestion = true;
      this.loader = false;
    }, err => {
      // console.log(err);
      this.loader = false;
    });
  }

  skuCode: any;
  searchSelect(product) {
    this.isSearchSuggestion = false;
    this.searchkey = product.name + '' + product.sku
    this.skuCode = product.sku
  }


  productItem: any;

  bulkProduck(form) {
    // console.log(form.value)
    this.isSubmit = true
    if (form.valid) {
      this.loader = true
      var request = {
        "sku": form.value.skuCode || null,
        "store_code": form.value.storeSelect || null,
        "status": form.value.status || null,
        "reason": form.value.reason || null,
        "quantity": +(form.value.quantity) || null,
      }
      this.service.productRemovel(request).subscribe((data) => {
        this.loader = false
        // console.log(data);
        this.productItem = data;
        if (data === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.productItem.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        }
      }, err => {
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: "No orders found with this SKU"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      })
      // console.log(request)
    }
  }

  walletPopup() {
    let requestBody = {
      "order_id": this.selectedOrderItem.order_id,
      "wallet_amount": this.selectedOrderItem.wallet_refund_amount
    }
    this.loader = true;
    this.sharedService.walletRevasal(requestBody).subscribe((data: any) => {
      this.walletRevalsal = data
      this.loader = false;
      // console.log(data)
      if (data.message) {
        document.getElementById("toggleWalletUp").click();
        this.selectedOrderItem = ''
      } else {
        $.notify({
          icon: "add_alert",
          message: "Wallet amount is not present"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      }
    }, erro => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Wallet amount is not present"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    })
  }

  selectedOrderItem
  refundWallet(item) {
    this.selectedOrderItem = item;
    // console.log(item)
    document.getElementById("toggleWalletUp").click()
  }
  fileURL: any;
  bulkProductPrint() {
    let orderIds = []
    this.selectedAlldata.forEach(item => {
      orderIds.push(item.order_id)
    })
    this.loader = true;
    if (orderIds.length) {
      let requestBody = {
        "order_id": orderIds
      }
      this.sharedService.bulkProdukPrint(requestBody).subscribe((data: any) => {
        // console.log(data)
        this.loader = false;
        var blob = new Blob([data], { type: 'application/pdf' }); //this make the magic
        var blobURL = URL.createObjectURL(blob);

        var iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };

      })
    } else {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Please Select"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }


  }
  playAudio() {
    let audio = new Audio();

    audio.src = "assets/sounds/buzzer.wav"
    audio.load();
    audio.play();
  }

  getOrdersbyselectedOMSID(selectedOMSData) {
    // console.log(selectedOMSData);
    this.selectedOMSIDorderID = [];
    // console.log(this.pagedItems);

    for (var i = 0; i < this.pagedItems.length; i++) {
      if (selectedOMSData.order_no == this.pagedItems[i].order_no) {
        var obj = {
          selectedChannelID: this.pagedItems[i].channelId
        }
        this.selectedOMSIDorderID.push(obj)
      }
    }
    // console.log(this.selectedOMSIDorderID);
    this.selectedOMSIDorderIDfinal = this.selectedOMSIDorderID[0].selectedChannelID

  }




  checkorderstatus() {
    // this.orderzipCode
    this.loader = true;
    this.orderEmail
    this.returnOrderIdd

    // // console.log(this.orderzipCode);
    // console.log(this.orderEmail);
    // console.log(this.returnOrderIdd);

    // var selectedPincodeChange = this.orderzipCode;
    // if (selectedPincodeChange.includes("-")) {
    //   var splitedInfo = selectedPincodeChange.split('-');
    //   selectedPincodeChange = splitedInfo[0];
    // }

    var requestbody = {
      "order_no": this.returnOrderIdd,
      "email": encodeURIComponent(this.orderEmail),
      // "pincode": selectedPincodeChange,
      "permission": this.permissionInfo,
      "user_email": encodeURIComponent(localStorage.getItem('User_Email')),

    }
    this.service.searchcheckorderstatus(requestbody).subscribe(res => {
      // console.log(res);

      this.itemsData = res[0];

      this.returnorderstatus = true;

      this.shippedStatusCount = [];
      this.presentCheckBoxIndex = [];
      for (var i = 0; i < this.itemsData.items.length; i++) {
        if (this.itemsData.items[i].item_status == "Shipped") {
          this.shippedStatusCount.push(this.itemsData.items[i].item_status);
          this.presentCheckBoxIndex.push(i);
        }

      }

      // console.log(this.shippedStatusCount);

      this.Customerbillingaddresses = this.itemsData.addresses[0].billing_address[0]
      this.Customershippingaddresses = this.itemsData.addresses[0].shipping_address[0]
      // console.log(this.Customerbillingaddresses);
      // console.log(this.Customershippingaddresses);




      this.checkCondition();
      this.loader = false;

    }, err => {
      // console.log(err);

      this.showThis45DaysError = err.error.error_desc;
      this.returnorderstatus = false;
      this.loader = false;


    })



  }

  checkCondition() {


    // console.log(this.itemsData.items);
    if (this.logInUser == "CRM Manager" || this.logInUser == "IT User" || this.logInUser == "Admin") {
      this.hideThisShipperShippingInfoBlock = true;
      // console.log(this.hideThisShipperShippingInfoBlock);
      this.loader = false;

    } else {
      var checkConditionArray = [];
      this.itemsData.items.filter(item => {
        if (item.message == "Initiate_return") {
          checkConditionArray.push(true);
        } else {
          checkConditionArray.push(false);
        }
      });
      // console.log(checkConditionArray);
      if (checkConditionArray.includes(true)) {
        this.hideThisShipperShippingInfoBlock = true;
      } else {
        this.hideThisShipperShippingInfoBlock = false;
      }
      // console.log(this.hideThisShipperShippingInfoBlock);
      this.loader = false;

    }
  }



  amountSelected(val) {
    // console.log(val);

    this.shopifyamountselectedvalue = val;


    this.amount = val.trim() ? parseFloat(val) : 0;
    // console.log("Amount", this.amount);
    // console.log(this.maximum_amount);
    this.shopifyPaymentsRefunds = this.amount;
    this.ReqAmount = this.shopifyPaymentsRefunds === 0 ? 0 : this.amount;
    this.finalReqAmount = parseInt(this.ReqAmount.toString());
    if (!val) { // Check if the input value is empty
      this.ReqAmount = 0; // Set ReqAmount to 0 if input value is empty
      this.finalReqAmount = 0; // Set finalReqAmount to 0 if input value is empty
      this.isSubmitDisabled = false; // Enable the submit button if input value is empty
    } else if (this.amount > this.maximum_amount.unit_price) {
      this.isSubmitDisabled = true; // Disable the submit button if input value is greater than maximum refundable amount
      // console.log(this.showcasetheGCvalueblock);

      // if (this.showcasetheGCvalueblock == true) {

      //   $.notify({
      //     icon: "add_alert",
      //     message: `Entered amount should not exceed maximum amount of $ ${this.maximum_amount.max_refund_amount}`
      //   }, {
      //     type: 'info',
      //     timer: 1000,
      //     placement: {
      //       from: 'top',
      //       align: 'center'
      //     }
      //   });
      // } else {
      $.notify({
        icon: "add_alert",
        message: `Entered amount should not exceed maximum amount of $ ${this.maximum_amount.unit_price}`
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
      // }

    } else {
      this.isSubmitDisabled = false;
      // console.log(this.singlecheckboxSelectedItem);
      // console.log(this.amount);
      // console.log(this.selectedGCvalue);

      var editedAmount = Number(val);
      var responseAmountFromAPI = parseFloat(this.singlecheckboxSelectedItem.price);
      var enteredGCvalue = Number(this.selectedGCvalue);

      // console.log(enteredGCvalue);
      // console.log(editedAmount);
      // console.log(responseAmountFromAPI);

      if (this.selectedGCvalue != null || this.selectedGCvalue != undefined) {
        var sumoftwoValues = enteredGCvalue + editedAmount;
        // console.log(sumoftwoValues);
        this.shopifygiftCartamountSum = sumoftwoValues
        if (sumoftwoValues > responseAmountFromAPI) {
          $.notify({
            icon: "add_alert",
            message: `Appeasement value cannot exceed $ ${responseAmountFromAPI}`
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          this.isSubmitDisabled = true;
        } else {
          this.isSubmitDisabled = false; // Enable the submit button if input value is less than or equal to maximum refundable amount
        }

      }

    }

  }


  GCSelected(val) {
    // console.log(val);
    this.selectedGCvalue = val;

    this.isSubmitDisabled = val.trim().length === 0;


    this.selectedValue = val;

    this.GCamount = val.trim() ? parseFloat(val) : 0;
    this.GCamountForPrice = val.trim() ? parseFloat(val) : 0;

    // console.log("GC Amount", this.GCamount);

    this.Reqgcrefund = this.shopifyPayment_gcmaximum_refundable === 0 ? 0 : this.GCamount;
    this.finalgcAmount = parseInt(this.Reqgcrefund.toString());
    // console.log(this.maximum_amount.gc_refund_amount);


    if (!val) { // Check if the input value is empty
      this.ReqAmount = 0; // Set ReqAmount to 0 if input value is empty
      this.finalReqAmount = 0; // Set finalReqAmount to 0 if input value is empty
      this.isSubmitDisabled = false; // Enable the submit button if input value is empty
    } else if (this.GCamount > this.maximum_amount.gc_refund_amount) {
      this.isSubmitDisabled = true; // Disable the submit button if input value is greater than maximum refundable amount
      $.notify({
        icon: "add_alert",
        message: `Entered Gift Card amount should not exceed maximum amount of $ ${this.maximum_amount.gc_refund_amount}`
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    } else {
      if (this.showcasetheonlyGCvalueblock == true) {
        if (this.GCamount > this.maximum_amount.unit_price) {
          this.isSubmitDisabled = true; // Disable the submit button if input value is greater than maximum refundable amount
          $.notify({
            icon: "add_alert",
            message: `Entered Gift Card amount should not exceed maximum amount of $ ${this.maximum_amount.unit_price}`
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {

          this.finalReqAmount = this.GCamount;
          this.isSubmitDisabled = false; // Enable the submit button if input value is less than or equal to maximum refundable amount
          // console.log(this.singlecheckboxSelectedItem);
          // console.log(this.amount);
          // console.log(this.selectedGCvalue);
          // console.log(this.shopifyamountselectedvalue);


          var editedGCAmount = Number(val);
          var responseAmountFromAPI = parseFloat(this.singlecheckboxSelectedItem.price);
          var enteredshopifyvalue = Number(this.shopifyamountselectedvalue);

          // console.log(enteredshopifyvalue);
          // console.log(editedGCAmount);
          // console.log(responseAmountFromAPI);

          if (enteredshopifyvalue != null || enteredshopifyvalue != undefined) {
            var sumoftwoValues = enteredshopifyvalue + editedGCAmount;
            // console.log(sumoftwoValues);
            this.shopifygiftCartamountSum = sumoftwoValues
            if (sumoftwoValues > responseAmountFromAPI) {
              $.notify({
                icon: "add_alert",
                message: `Appeasement value cannot exceed $ ${responseAmountFromAPI}`
              }, {
                type: 'info',
                timer: 1000,
                placement: {
                  from: 'top',
                  align: 'center'
                }
              });
              this.isSubmitDisabled = true;
            } else {
              this.isSubmitDisabled = false;
            }

          }
        }
      } else {
        this.finalReqAmount = this.GCamount;
        this.isSubmitDisabled = false; // Enable the submit button if input value is less than or equal to maximum refundable amount
        // console.log(this.singlecheckboxSelectedItem);
        // console.log(this.amount);
        // console.log(this.selectedGCvalue);
        // console.log(this.shopifyamountselectedvalue);


        var editedGCAmount = Number(val);
        var responseAmountFromAPI = parseFloat(this.singlecheckboxSelectedItem.price);
        var enteredshopifyvalue = Number(this.shopifyamountselectedvalue);

        // console.log(enteredshopifyvalue);
        // console.log(editedGCAmount);
        // console.log(responseAmountFromAPI);

        if (enteredshopifyvalue != null || enteredshopifyvalue != undefined) {
          var sumoftwoValues = enteredshopifyvalue + editedGCAmount;
          // console.log(sumoftwoValues);
          this.shopifygiftCartamountSum = sumoftwoValues
          if (sumoftwoValues > responseAmountFromAPI) {
            $.notify({
              icon: "add_alert",
              message: `Appeasement value cannot exceed $ ${responseAmountFromAPI}`
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.isSubmitDisabled = true;
          } else {
            this.isSubmitDisabled = false;
          }

        }
      }

    }

  }
  appeasementTypeSselect(selectedData, val) {

    this.justSelectedSandH = false;

    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "appeasement_filter") {
          if (val.target.value != null && val.target.value == "default") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }
    //ApplyDiscount
    //Appeasement
    this.hideTheFinalSubmitButton = true;
    // console.log(selectedData);
    this.selectedForDiscount = selectedData;
    // console.log(this.itemsData.items);

    if (selectedData == "ApplyDiscount") {
      this.selectedForOrderAppeasementType = null;
      if (this.itemsData.is_apply_discount == false) {
        $('.applyDiscountLogicTwo').css("display", "none");
        $('.applyDiscountLogicone').css("display", "none");
        $('.singlechkbox').css("display", "inline");
        this.shippedStatusCount = [];
        this.selectAllChecked = true
        this.hideTheCheckBoxDefault = false
        for (var i = 0; i < this.itemsData.items.length; i++) {
          if (this.itemsData.items[i].item_status == "Shipped" || this.itemsData.items[i].item_status == "Order In Process" || this.itemsData.items[i].item_status == "Order Placed") {
            this.shippedStatusCount.push(this.itemsData.items[i].item_status);
          }
          if (this.itemsData.items[i].digital_discount == true) {
            $('#dynamicIDNew' + i).css("display", "none");
          }
        }
      } else {
        this.shippedStatusCount = [];
        for (var i = 0; i < this.itemsData.items.length; i++) {
          if (this.itemsData.items[i].item_status == "Shipped" || this.itemsData.items[i].item_status == "Order In Process" || this.itemsData.items[i].item_status == "Order Placed") {
            this.shippedStatusCount.push(this.itemsData.items[i].item_status);
          }

        }
      }

      this.selectedAppeasement = false;

      this.hideTheCheckBoxDefault = false;
      for (let j = 0; j < this.itemsData.items.length; j++) {
        $('#dynamicIDNew' + j).prop("checked", true);
        $('#dynamicIDNew' + j).prop("disabled", true);
        this.itemsData.items[j].adjusted_amount = 0;
      }
      this.activeApplyDiscount = true;
      this.activeApplyDiscountTwo = false;
      this.activeAppeasement = false;
      this.activeAppeasementSub = false;
      this.activeAppeasementSubItemTax = false;
      this.activeAppeasementSubLineItem = false;
      this.selectedOptionAppeasementSub = 'default';
      this.showThisMessageNow = false;
      this.selectAllChecked = true;
      $('#selectall').prop("checked", true);

    } else {
      if (this.itemsData.is_apply_discount == false) {
        this.shippedStatusCount = [];
        for (var i = 0; i < this.itemsData.items.length; i++) {
          if (this.itemsData.items[i].item_status == "Shipped" || this.itemsData.items[i].item_status == "Appeasement") {
            this.shippedStatusCount.push(this.itemsData.items[i].item_status);
          }
        }
        // console.log(this.shippedStatusCount);
        if (this.shippedStatusCount.length == 0) {
          $('.applyDiscountLogicTwo').css("display", "inline");
          $('.applyDiscountLogicone').css("display", "inline");
          $('.singlechkbox').css("display", "none");
        }

      } else {
        this.shippedStatusCount = [];
        for (var i = 0; i < this.itemsData.items.length; i++) {
          if (this.itemsData.items[i].item_status == "Shipped" || this.itemsData.items[i].item_status == "Appeasement") {
            this.shippedStatusCount.push(this.itemsData.items[i].item_status);
          }
        }
      }
      this.selectedAppeasement = true;
      this.selectAllChecked = false;
      this.hideTheCheckBoxDefault = true;
      this.hideTheSingleCheckBoxDefault = true;
      this.activeApplyDiscount = false;
      this.activeApplyDiscountTwo = false;

      this.activeAppeasement = true;
      this.activeApplyDiscountValue = 0;
      for (let j = 0; j < this.itemsData.items.length; j++) {
        $('#dynamicIDNew' + j).prop("checked", false);
        $('#dynamicIDNew' + j).prop("disabled", false);
        this.itemsData.items[j].adjusted_amount = 0;

      }

    }


  }

  appeasementSubSelect(selectedData, val) {

    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "appeasement_type_filter") {
          if (val.target.value != null && val.target.value == "default") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }
    // console.log(selectedData);
    // SandHtax
    // ItemTax
    // LineItem

    this.selectedForOrderAppeasementType = selectedData;

    if (selectedData == "SandHtax") {
      this.justSelectedSandH = true;
      this.selectedForDiscount = null;
      this.hideTheSingleCheckBoxDefault =true;

      this.loader = true;
      this.selectedAppeasement = false;
      this.selectAllChecked = true;
      this.hideTheCheckBoxDefault = false;
      this.activeAppeasementSubLineItem = false;
      this.activeAppeasementSubItemTax = false;
      for (let j = 0; j < this.itemsData.items.length; j++) {
        $('#dynamicIDNew' + j).prop("checked", true);
        $('#dynamicIDNew' + j).prop("disabled", true);
      }
      this.activeApplyDiscount = false;
      this.activeAppeasement = true;
      this.getSelectedOrder_part_noForAppeasment = [];
      this.itemsData.items.filter(item => {
        // if (item.item_status == "Shipped" || item.item_status == "Appeasement") {
          this.getSelectedOrder_part_noForAppeasment.push(item.order_part_no);
        // }
      });
      // console.log(this.getSelectedOrder_part_noForAppeasment);

      var requestBody = {
        "key": "s&h_charge",
        "order_no": this.itemsData.order_no,
        "order_part_no": this.getSelectedOrder_part_noForAppeasment,
        "discount": 0,
        "amount_customised": 0,
        "item_tax": false,
        "ship_tax": true
      }
      // console.log(requestBody);
      if (requestBody.order_part_no.length == 0) {
        this.hideTheFinalSubmitButton = true;
        this.loader = false;
      } else {
        this.service.applydiscountForAppeasment(requestBody).subscribe(res => {
          // console.log(res);
          this.selectedSandHAppeasmentRes = res;
          if (this.selectedSandHAppeasmentRes.message) {
            this.activeAppeasementSub = false;
            this.showThisMessageNow = true;
            this.hideTheFinalSubmitButton = true;
            this.inputValueLineItemChange = null;
            this.loader = false;
            $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
            $('#appeasementTransactionspopup').modal('hide');
          } else {
            this.activeAppeasementSub = true;
            this.showThisMessageNow = false;
            this.hideTheFinalSubmitButton = false;
            this.inputValueLineItemChange = null;
            this.loader = false;
            $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
            $('#appeasementTransactionspopup').modal('hide');
          }
        }, err => {
          // console.log(err);
          this.hideTheFinalSubmitButton = true;
          this.loader = false;
          $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
          $('#appeasementTransactionspopup').modal('hide');
        });

      }
    }
    if (selectedData == "ItemTax") {
      this.selectedForDiscount = null;
      this.justSelectedSandH = false;
      this.hideTheSingleCheckBoxDefault =false;

      this.selectedAfterAppeasement = selectedData;
      this.activeAppeasementSubLineItem = false;
      // console.log(selectedData);
      this.hideTheFinalSubmitButton = true;
      this.inputValueLineItemChange = null;
      this.selectedAppeasement = false;
      this.activeApplyDiscount = false;
      this.activeAppeasementSub = false;
      this.activeAppeasement = true;
      this.selectAllChecked = false;
      this.hideTheCheckBoxDefault = true;
      this.showThisMessageNow = false;
      for (let j = 0; j < this.itemsData.items.length; j++) {
        $('#dynamicIDNew' + j).prop("checked", false);
        $('#dynamicIDNew' + j).prop("disabled", false);
      }
    }

    if (selectedData == "LineItem") {
      this.justSelectedSandH = false;
      this.selectedForDiscount = null;
      this.hideTheSingleCheckBoxDefault =false;

      // console.log(selectedData);
      this.selectedAfterAppeasement = selectedData;
      this.selectedAppeasement = false;
      this.activeApplyDiscount = false;
      this.activeAppeasementSub = false;
      this.activeAppeasementSubItemTax = false;
      this.activeAppeasement = true;
      this.selectAllChecked = false;
      this.hideTheCheckBoxDefault = true;
      this.showThisMessageNow = false;

      this.inputValueLineItemChange = null;
      this.hideTheFinalSubmitButton = true;


      for (let j = 0; j < this.itemsData.items.length; j++) {
        $('#dynamicIDNew' + j).prop("checked", false);
        $('#dynamicIDNew' + j).prop("disabled", false);
      }
    }
  }
  totalRefundAmountCheck(event) {
    // console.log(event.target.value);
    if (event.target.value) {
      if (event.target.value > this.selectedLineItemAppeasmentRes.total_refund_amount) {
        this.hideTheFinalSubmitButton = true;
      } else {
        this.hideTheFinalSubmitButton = false;
      }
    } else {
      this.hideTheFinalSubmitButton = true;
    }
  }

  checktheChanges(selectedVal) {
    // console.log(selectedVal);
    // this.loader = true;
    // console.log(this.activeApplyDiscountValue);
    if (selectedVal <= 0 || selectedVal > 100) {
      this.activeApplyDiscountValue = 0;
      this.hideTheFinalSubmitButton = true;
      this.activeApplyDiscountTwo = false;
      for (let j = 0; j < this.itemsData.items.length; j++) {
        this.itemsData.items[j].adjusted_amount = 0;
      }
      // this.loader = false;

    } else {
      // console.log(selectedVal);
      // console.log(this.itemsData.items);
      var getSelectedOrder_part_no = [];
      this.itemsData.items.filter(item => {
        if (((item.item_status == "Shipped" || item.item_status == "Order In Process" || item.item_status == "Order Placed") && (item.digital_discount == false))) {
          getSelectedOrder_part_no.push(item.order_part_no);
        }
      });
      console.log(getSelectedOrder_part_no);

      var requestBody = {
        "key": "discount",
        "order_no": this.itemsData.order_no,
        "order_part_no": getSelectedOrder_part_no,
        "discount": selectedVal,
        "amount_customised": 0,
        "item_tax": true,
        "ship_tax": true
      }
      // console.log(requestBody);
      this.service.applydiscountForAppeasment(requestBody).subscribe(res => {
        // console.log(res);
        this.applyDiscountResponse = res;
        // console.log(this.itemsData.items);
        // console.log(this.applyDiscountResponse.order_part_no);
        // console.log(this.applyDiscountResponse.order_part_no_details);
        this.activeApplyDiscountTwo = true;
        // this.itemsData.items.forEach((item, index) => {
        //   this.applyDiscountResponse.order_part_no_details.filter((data, newIndex) => {
        //     if (item.order_part_no == data.order_part_no) {
        //       this.itemsData.items[index]['adjusted_amount'] = data.adjusted_amount
        //     } else {
        //       this.itemsData.items[index]['adjusted_amount'] = data.adjusted_amount
        //     }
        //   })
        // })
        this.hideTheFinalSubmitButton = false;
        this.loader = false;
        $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
        $('#appeasementTransactionspopup').modal('hide');
        console.log(this.hideTheFinalSubmitButton);
        console.log(this.applyDiscountResponse.order_part_no_details);
        this.itemsData.items.forEach((item, index) => {
          const matchingOrderPartNo = this.applyDiscountResponse.order_part_no_details.find((data) => {
            if (item.order_part_no === data.order_part_no) {
              return item.order_part_no === data.order_part_no;
            }
          });
          // console.log(item.order_part_no);
          // console.log(matchingOrderPartNo);
          // console.log(matchingOrderPartNo, index);
          if (matchingOrderPartNo) {
            this.itemsData.items[index]['adjusted_amount'] = matchingOrderPartNo.adjusted_amount;
          } else {
            this.itemsData.items[index]['adjusted_amount'] = 0;
          }
        });


        // for (var k = 0; k <= this.itemsData.items.length; k++) {
        //   for (var j = 0; j <= this.applyDiscountResponse.order_part_no_details.length; j++) {
        //     if (this.itemsData.items[k].order_part_no == this.applyDiscountResponse.order_part_no_details[j].order_part_no) {
        //       // this.itemsData.items[i]['adjusted_amount'] = this.applyDiscountResponse.order_part_no_details[i].adjusted_amount
        //       // console.log(j);
        //     } else {
        //       // this.itemsData.items[i]['adjusted_amount'] = this.applyDiscountResponse.order_part_no_details[i].adjusted_amount
        //     }
        //   }
        // }


        // console.log(this.itemsData.items);

      }, err => {
        console.log(err);
        this.hideTheFinalSubmitButton = true;
        this.activeApplyDiscountTwo = false;
        this.loader = false;

      });
    }
  }
  returnreasonsselect(selectedData, val) {
    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "reason_filter") {
          if (val.target.value != null && val.target.value == "default") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }
    // console.log(selectedData);
    this.selectedReturenReason = selectedData;

    if (selectedData === 'Damaged/defective item' || selectedData === 'Billing issue' || selectedData === 'Credit shipping fee' || selectedData === 'Inconvenience' || selectedData === 'Late shipment' || selectedData === 'Missing item' || selectedData === 'Negative customer review' || selectedData === 'Order not received/delivery error' || selectedData === 'Other' || selectedData === 'Poor customer service' || selectedData === 'Price adjustment' || selectedData === 'Promotion was not correctly applied' || selectedData === 'Sales tax' || selectedData === 'Wrong item shipped') {
      this.showFields = true;
      this.showThisAppeasementDropDown = true;
      // this.hideTheFinalSubmitButton = false;
    } else {
      this.showFields = false;
      this.showThisAppeasementDropDown = false;
      // this.hideTheFinalSubmitButton = true;

    }
    // this.loader = true;
    this.reason_line_itemid = this.itemsData.items[0].line_item_id
    var finalSelectedCheckBoxes = [];
    var finalSelectedCheckBoxesOrder_lineitem_id = [];

    var finalSelectedCheckBoxesOrder_order_parts_no = [];

    this.allChecked = true;
    for (let i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true) {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);

        if (this.itemsData.items[i].item_status === "Shipped") {
          finalSelectedCheckBoxesOrder_lineitem_id.push(this.itemsData.items[i].line_item_id);
        }
      } else {
        this.allChecked = false;
      }
    }

    // *****order parts code******
    for (let i = 0; i < this.itemsData.items.length; i++) {

      if ($('#dynamicIDNew' + i).prop('checked') == true) {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);
        finalSelectedCheckBoxes.push(false);
        if (this.itemsData.items[i].item_status === "Shipped") {
          finalSelectedCheckBoxesOrder_order_parts_no.push(this.itemsData.items[i].order_part_no);
        }
      }
      else {
        this.allChecked = false;

      }
    }
    // *****order parts code******
    this.full_refund_amount = this.allChecked ? true : false;
    // console.log(this.appeasementResultSelectedCheckbox);

    this.reqstbdy = {

      "channel_name": this.itemsData.channel,
      "items": finalSelectedCheckBoxesOrder_lineitem_id,
      "full_refund": this.appeasementResultSelectedCheckbox.full_refund,
      "order_parts": finalSelectedCheckBoxesOrder_order_parts_no,
      // "channel_name": "STRIDE RITE",
      // "items":["13884079014198"]

    }
    // console.log(this.reqstbdy);



    // this.service.newappeasementreason(this.itemsData.order_no, this.reqstbdy).subscribe(res => {
    //   this.loader = false;
    //   // console.log(res);
    //   this.appeasementResultSelectedCheckbox = res;
    //   // console.log(this.appeasementResultSelectedCheckbox);
    //   if (this.appeasementResultSelectedCheckbox.is_customizable == true) {
    //     this.showTheEnterCustomeAmount = true;
    //     this.inputValue = '';
    //   } else {
    //     this.showTheEnterCustomeAmount = false;
    //   }
    //   /*Dual payment condition */
    //   if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.gc_refund_amount > 0) {
    //     this.showcasetheGCvalueblock = true;
    //   } else {
    //     this.showcasetheGCvalueblock = false;

    //   }

    //   /*Dual payment condition */

    //   /*Only Gift card payment code */
    //   if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.max_refund_amount == 0) {
    //     this.showcasetheonlyGCvalueblock = true;
    //   } else {
    //     this.showcasetheonlyGCvalueblock = false;

    //   }


    //   /*Only Gift card payment code */
    //   this.maximum_amount = res;

    //   if (this.maximum_amount.error_msg) {

    //     const errmsg = this.maximum_amount.error_msg;
    //     const objectmsg = Object.keys(errmsg).map(key => errmsg[key])[0];
    //     // console.log(objectmsg);

    //     $.notify({
    //       icon: "add_alert",
    //       message: objectmsg,
    //     }, {
    //       type: 'info',
    //       timer: 1000,
    //       placement: {
    //         from: 'top',
    //         align: 'center'
    //       }
    //     });
    //   }



    //   this.S_and_H = this.maximum_amount['S&H']
    //   this.shopifyPaymentsRefunds = this.maximum_amount.unit_price
    //   this.shopifyPayment_gcmaximum_refundable = 0;
    //   this.Reqgcrefund = this.shopifyPayment_gcmaximum_refundable === 0 ? 0 : this.finalgcAmount;
    //   this.ReqAmount = this.shopifyPaymentsRefunds === 0 ? 0 : this.finalReqAmount;

    //   // console.log(this.Reqgcrefund);
    //   // console.log(this.shopifyPaymentsRefunds)
    //   this.transactions = this.maximum_amount.transactions

    // }, err => {




    // });




  }


  shipmentoptionselect(selectedData) {
    // console.log(selectedData);
    this.selectedShipperName = selectedData;
    this.shipmentservicetypes();
  }

  shipmentservicetypes() {

    var reqbody = {
      "shipping_option": this.selectedShipperName
    }

    this.service.gethipmentservicetypes(reqbody).subscribe(res => {
      // console.log(res);
      this.shipperServiceData = res;
      this.shipperServiceInfo = this.shipperServiceData.shipping_service_type
    }, err => {
      // console.log(err);
    });
  }

  shipperServiceSelectedInfo(selectedData) {
    // console.log(selectedData);
    this.selectedShipperServiceData = selectedData;
  }
  // ---------------------------------------------
  returnTypeSelectedInfo(selectedType) {
    // console.log(selectedType);
    this.selectedReturnTypeInfo = selectedType;
  }
  // ---------------------------------------------

  selectAllItems(event) {
    this.selectedOption = 'default';
    // this.justSelectedSandH = false;
    // console.log(event.target.checked);
    if (event.target.checked == true) {
      this.setTheDefaultView = true;
      for (let j = 0; j < this.itemsData.items.length; j++) {
        $('#dynamicIDNew' + j).prop("checked", true);
        $('#dynamicIDNew' + j).prop("disabled", true);
      }
    } else {
      this.setTheDefaultView = false;
      this.showThisAppeasementDropDown = false;
      this.activeApplyDiscount = false;


      for (let j = 0; j < this.itemsData.items.length; j++) {
        $('#dynamicIDNew' + j).prop("checked", false);
        $('#dynamicIDNew' + j).prop("disabled", false);

      }
      this.selectedOption = 'default';
      this.selectedOptionAppeasementType = 'default';
      this.selectedOptionAppeasementSub = 'default';
      this.hideTheCheckBoxDefault = true;
      this.hideTheSingleCheckBoxDefault = true;

      this.activeAppeasement = false;
      this.activeAppeasementSub = false;
      this.activeApplyDiscountTwo = false;
      this.activeApplyDiscountValue = 0;
      this.hideTheFinalSubmitButton = true;
      // this.inputValueLineItemChange = null;
      // this.selectedAppeasement = true;

      this.checkorderstatus();


    }
    this.inputValue = '';
    this.inputValuee = '';
    // this.selectedOrderDetails = selectedOrder;
    var finalSelectedCheckBoxes = [];
    var finalSelectedCheckBoxesOrder_lineitem_id = [];
    var finalSelectedCheckBoxesOrder_order_parts_no = [];
    this.allChecked = !this.allChecked;
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if (($('#dynamicIDNew' + i).prop('checked') == true) && this.itemsData.items[i].item_status == "Shipped") {
        finalSelectedCheckBoxesOrder_order_parts_no.push(this.itemsData.items[i].order_part_no);
        finalSelectedCheckBoxesOrder_lineitem_id.push(this.itemsData.items[i].line_item_id);
        finalSelectedCheckBoxes.push(false);
      } else {
        finalSelectedCheckBoxes.push(true);
      }
    }


    // *****order parts code******
    this.refund_amount = this.allChecked;
    this.full_refund_amount = this.allChecked;
    this.reqstbdy = {
      "channel_name": this.itemsData.channel,
      "items": finalSelectedCheckBoxesOrder_lineitem_id,
      "order_parts": finalSelectedCheckBoxesOrder_order_parts_no

    };
    // console.log(this.reqstbdy);
    this.loader = true;
    this.service.newappeasementreason(this.itemsData.order_no, this.reqstbdy).subscribe(res => {
      this.loader = false;
      this.appeasementResultSelectedCheckbox = res;
      // console.log(this.appeasementResultSelectedCheckbox);

      if (this.appeasementResultSelectedCheckbox.is_customizable == true) {
        this.showTheEnterCustomeAmount = true;
      } else {
        this.showTheEnterCustomeAmount = false;
        this.inputValue = '';
      }

      if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.gc_refund_amount > 0) {
        this.showcasetheGCvalueblock = true;
      } else {
        this.showcasetheGCvalueblock = false;

      }
      /*Only Gift card payment code */
      if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.max_refund_amount == 0) {
        this.showcasetheonlyGCvalueblock = true;
      } else {
        this.showcasetheonlyGCvalueblock = false;

      }

      this.maximum_amount = res;
      if (this.maximum_amount.error_msg) {
        const errmsg = this.maximum_amount.error_msg;
        const objectmsg = Object.keys(errmsg).map(key => errmsg[key])[0];
        // console.log(objectmsg);

      }
      this.S_and_H = this.maximum_amount['S&H'];
      this.shopifyPaymentsRefunds = this.maximum_amount.unit_price;
      this.shopifyPayment_gcmaximum_refundable = 0
      this.Reqgcrefund = this.shopifyPayment_gcmaximum_refundable === 0 ? 0 : this.finalgcAmount;
      this.ReqAmount = this.shopifyPaymentsRefunds === 0 ? 0 : this.finalReqAmount;
      this.transactions = this.maximum_amount.transactions;
    }, err => {
      // console.log(err);
    });

    if (!this.allChecked) {
      this.refund_amount = false;
      this.full_refund_amount = false;
    }











    // console.log(finalSelectedCheckBoxes);

    if (finalSelectedCheckBoxes.includes(false)) {
      this.showthisitemafterclick = false;
    } else {
      this.showthisitemafterclick = true;
    }
    // console.log(this.showthisitemafterclick);



  }
  onItemChecked(event, item) {
    // console.log(item);
    this.singlecheckboxSelectedItem = item;
    // console.log(event.target.checked);
    this.loader = true;

    if (event.target.checked == true) {
      this.selectedOrderPartNumberDetails = item;
      // this.selectedOption = 'default';
      // disable all other checkboxes
      $('input[id^="dynamicIDNew"]').not(event.target).prop('disabled', true);
      $('#selectall').prop('disabled', true);
      this.loader = true;

      // console.log(this.selectedAfterAppeasement);
      if (this.selectedAfterAppeasement == "ItemTax") {
        this.loader = true;
        // var getSelectedOrder_part_noForAppeasment = [];
        // this.itemsData.items.filter(item => {
        //   if (item.item_status == "Shipped") {
        //     getSelectedOrder_part_noForAppeasment.push(item.order_part_no);
        //   }
        // });
        // console.log([this.selectedOrderPartNumberDetails.order_part_no]);

        var requestBody = {
          "key": "item_tax",
          "order_no": this.itemsData.order_no,
          "order_part_no": [this.selectedOrderPartNumberDetails.order_part_no],
          "discount": 0,
          "amount_customised": 0,
          "item_tax": true,
          "ship_tax": false
        }
        // console.log(requestBody);
        this.service.applydiscountForAppeasment(requestBody).subscribe(res => {
          // console.log(res);
          this.selectedItemTaxAppeasmentRes = res;
          if (this.selectedItemTaxAppeasmentRes.message) {
            this.selectedItemTaxAppeasmentResFinal = this.selectedItemTaxAppeasmentRes.message;
            this.showHideTheTitel = false;
            this.loader = false;
            // console.log(this.selectedItemTaxAppeasmentResFinal);
            this.hideTheFinalSubmitButton = true;
            this.showTheErrorMessage = true;
            $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
            $('#appeasementTransactionspopup').modal('hide');
          } else {
            this.selectedItemTaxAppeasmentResFinal = this.selectedItemTaxAppeasmentRes.total_refund_amount;
            this.showHideTheTitel = true;
            this.loader = false;
            // console.log(this.selectedItemTaxAppeasmentResFinal);
            this.hideTheFinalSubmitButton = false;
            this.showTheErrorMessage = false;
            $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
            $('#appeasementTransactionspopup').modal('hide');


          }
          this.loader = false;
          this.activeAppeasementSubItemTax = true;

        }, err => {
          // console.log(err);
          this.hideTheFinalSubmitButton = true;
          this.loader = false;
          $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
          $('#appeasementTransactionspopup').modal('hide');
        });
      }
      if (this.selectedAfterAppeasement == "LineItem") {
        this.loader = true;

        // var getSelectedOrder_part_noForAppeasmentLineItem = [];

        // this.itemsData.items.filter(item => {
        //   if (item.item_status == "Shipped") {
        //     getSelectedOrder_part_noForAppeasmentLineItem.push(item.order_part_no);
        //   }
        // });
        // console.log([this.selectedOrderPartNumberDetails.order_part_no]);

        var requestBody = {
          "key": "line_level",
          "order_no": this.itemsData.order_no,
          "order_part_no": [this.selectedOrderPartNumberDetails.order_part_no],
          "discount": 0,
          "amount_customised": 0,
          "item_tax": false,
          "ship_tax": false
        }
        // console.log(requestBody);
        this.service.applydiscountForAppeasment(requestBody).subscribe(res => {
          // console.log(res);
          this.selectedLineItemAppeasmentRes = res;
          this.loader = false;
          this.activeAppeasementSubLineItem = true;
          // this.hideTheFinalSubmitButton = false;

          $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
          $('#appeasementTransactionspopup').modal('hide');
        }, err => {
          // console.log(err);
          // this.hideTheFinalSubmitButton = true;
          this.loader = false;
          $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
          $('#appeasementTransactionspopup').modal('hide');
        });
      }
    } else {
      // enable all checkboxes
      $('input[id^="dynamicIDNew"]').prop('disabled', false);
      $('#selectall').prop('disabled', false);
      // this.selectedOption = 'default';
      this.activeAppeasementSubItemTax = false;
      this.activeAppeasementSubLineItem = false;
      this.hideTheFinalSubmitButton = true;
      this.inputValueLineItemChange = null;
      this.showThisMessageNow = false;
      this.loader = false;
    }

    var finalSelectedCheckBoxes = [];
    var finalSelectedCheckBoxesOrder_lineitem_id = [];
    var finalSelectedCheckBoxesOrder_order_parts_no = [];
    var allChecked = true;
    var atLeastOneSelected = false;

    this.allcheckboxes = [];
    for (let i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked')) {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);
        finalSelectedCheckBoxes.push(false);
        finalSelectedCheckBoxesOrder_lineitem_id.push(this.itemsData.items[i].line_item_id);
        atLeastOneSelected = true;
      } else {
        allChecked = false;
        finalSelectedCheckBoxes.push(true);
      }
    }

    for (let j = 0; j < this.itemsData.items.length; j++) {
      if (($('#dynamicIDNew' + j).prop('checked') == true) && this.itemsData.items[j].item_status == "Shipped") {
        this.allcheckboxes.push(true);
      } else {
        this.allcheckboxes.push(false);
      }
    }

    this.allcheckboxesNew = [];
    for (let j = 0; j < this.presentCheckBoxIndex.length; j++) {
      if ($('#dynamicIDNew' + this.presentCheckBoxIndex[j]).prop('checked') == true) {
        this.allcheckboxesNew.push(true);
      } else {
        this.allcheckboxesNew.push(false);
      }
    }

    for (let i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true) {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);
        finalSelectedCheckBoxes.push(false);
        if (this.itemsData.items[i].item_status === "Shipped") {
          finalSelectedCheckBoxesOrder_order_parts_no.push(this.itemsData.items[i].order_part_no);
        }
      }
      else {
        this.allChecked = false;
      }
    }

    if (this.allcheckboxesNew.includes(false)) {
      $('#selectall').prop("checked", false);
    } else {
      $('#selectall').prop("checked", true);
    }

    this.reqstbdy = {
      "channel_name": this.itemsData.channel,
      "items": finalSelectedCheckBoxesOrder_lineitem_id,
      "order_parts": finalSelectedCheckBoxesOrder_order_parts_no
    }


    this.showIncludeTax = atLeastOneSelected && !this.reqstbdy.full_refund; // hide if full_refund is true

    this.loader = true;
    // console.log("reason", this.itemsData.items[0].line_item_id);
    // console.log("reason", this.itemsData.order_no);

    this.loader = false;

    // this.service.newappeasementreason(this.itemsData.order_no, this.reqstbdy).subscribe(res => {
    //   this.loader = false;
    //   this.appeasementResultSelectedCheckbox = res;

    //   // console.log(this.appeasementResultSelectedCheckbox);
    //   if (this.appeasementResultSelectedCheckbox.is_customizable == true) {
    //     this.showTheEnterCustomeAmount = true;
    //   } else {
    //     this.showTheEnterCustomeAmount = false;
    //     this.inputValue = '';
    //   }
    //   if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.gc_refund_amount > 0) {
    //     this.showcasetheGCvalueblock = true;
    //   } else {
    //     this.showcasetheGCvalueblock = false;

    //   }

    //   /*Only Gift card payment code */
    //   if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.max_refund_amount == 0) {
    //     this.showcasetheonlyGCvalueblock = true;
    //   } else {
    //     this.showcasetheonlyGCvalueblock = false;

    //   }


    //   // console.log(atLeastOneSelected);
    //   if (atLeastOneSelected == true) {
    //     this.setTheDefaultView = true;
    //   } else {
    //     this.setTheDefaultView = false;

    //   }
    //   this.inputValue = '';
    //   // console.log(this.allcheckboxes);



    //   this.maximum_amount = res;
    //   if (this.maximum_amount.error_msg) {

    //     const errmsg = this.maximum_amount.error_msg;
    //     const objectmsg = Object.keys(errmsg).map(key => errmsg[key])[0];
    //     // console.log(objectmsg);

    //     $.notify({
    //       icon: "add_alert",
    //       message: objectmsg,
    //     }, {
    //       type: 'info',
    //       timer: 1000,
    //       placement: {
    //         from: 'top',
    //         align: 'center'
    //       }
    //     });
    //   }


    //   this.S_and_H = this.maximum_amount['S&H']
    //   this.shopifyPaymentsRefunds = this.maximum_amount.unit_price
    //   this.shopifyPayment_gcmaximum_refundable = 0
    //   this.Reqgcrefund = this.shopifyPayment_gcmaximum_refundable === 0 ? 0 : this.finalgcAmount;
    //   this.ReqAmount = this.shopifyPaymentsRefunds === 0 ? 0 : this.finalReqAmount;

    //   // console.log(this.Reqgcrefund);
    //   // console.log(this.shopifyPaymentsRefunds)
    //   this.transactions = this.maximum_amount.transactions
    //   if (this.allcheckboxesNew.includes(false)) {
    //     $('#selectall').prop("checked", false);
    //   } else {
    //     $('#selectall').prop("checked", true);
    //   }

    // }, err => {
    //   // console.log(err);
    //   this.loader = false;



    // });
    if (finalSelectedCheckBoxes.includes(false)) {
      this.showthisitemafterclick = false;
    } else {
      this.showthisitemafterclick = true;
    }
    // console.log(this.showthisitemafterclick);
    if (finalSelectedCheckBoxes.includes(false)) {
      this.showIncludeTaxDiv = false;
    } else {
      this.showIncludeTaxDiv = true;
    }
  }

  taxSelectedOrNotForLineItem(event, tax) {

    // console.log(this.inputValueLineItemChange);
    // console.log(tax);
    this.taxSelectedForLineItem = tax;
    if (event.target.checked == true) {
      this.taxSelectedForLintItem = true;
      this.itemtaxCheckboxChecked = true;
      // this.shareThisForlineItemValue = this.inputValueLineItemChange + tax;
    } else {
      this.taxSelectedForLintItem = false;
      this.itemtaxCheckboxChecked = false;
      // this.shareThisForlineItemValue = this.inputValueLineItemChange;
    }
    // // console.log(this.shareThisForlineItemValue);
  }

  taxSelectedOrNot(event, tax) {
    // console.log(event.target.checked);
    // console.log(tax);
    // console.log(this.shopifyPaymentsRefunds);
    // console.log(this.showcasetheonlyGCvalueblock);
    this.taxAmount = tax;
    if (event.target.checked == true) {
      this.taxCheckBoxChecked = true;
      this.shopifyPaymentsRefunds = this.shopifyPaymentsRefunds + tax
      if (this.showcasetheonlyGCvalueblock == true) {
        this.GCamount = this.GCamount + tax;
      }
    } else {
      this.taxCheckBoxChecked = false;

      this.shopifyPaymentsRefunds = this.shopifyPaymentsRefunds - tax
      if (this.showcasetheonlyGCvalueblock == true) {
        this.GCamount = this.GCamount - tax;
      }
    }
    // console.log(this.shopifyPaymentsRefunds);
    // console.log(this.GCamount);


  }

  sAndHSelectedOrNot(event, SandH) {
    // console.log(event);
    // console.log(SandH);
    if (event.target.checked == true) {
      this.finalSandHValue = SandH;
      this.sandhCheckBoxChecked = true;
    } else {
      this.finalSandHValue = 0;
      this.sandhCheckBoxChecked = false;

    }

  }

  appeasmentTrasaction() {

    // this.loader = true;



    // console.log(this.itemsData);
    // console.log(this.selectedOrderDetails);

    // console.log(this.selectedForOrderAppeasementType);
    // console.log(this.selectedForDiscount);
    if (this.selectedForDiscount == "ApplyDiscount") {
      reqbody = {
        "key": "discount",
        "channel_name": this.itemsData.channel,
        "order_ref_id": this.itemsData.order_ref_id,
        "order_part_no": this.applyDiscountResponse.order_part_no_details,
        "refund_amount": this.applyDiscountResponse.total_refund_amount
      }
    }

    if (this.selectedForOrderAppeasementType == "SandHtax") {
      var reqbody = {
        "key": "s&h_charge",
        "channel_name": this.itemsData.channel,
        "order_ref_id": this.itemsData.order_ref_id,
        "order_part_no": this.getSelectedOrder_part_noForAppeasment,
        "refund_amount": this.selectedSandHAppeasmentRes.total_refund_amount
      }


    }
    if (this.selectedForOrderAppeasementType == "ItemTax") {
      reqbody = {
        "key": "item_tax",
        "channel_name": this.itemsData.channel,
        "order_ref_id": this.itemsData.order_ref_id,
        "order_part_no": [this.selectedOrderPartNumberDetails.order_part_no],
        "refund_amount": this.selectedItemTaxAppeasmentRes.total_refund_amount
      }
    }
    if (this.selectedForOrderAppeasementType == "LineItem") {


      // console.log(this.inputValueLineItemChange);
      // console.log(this.taxSelectedForLintItem);
      if (this.taxSelectedForLintItem == true) {
        this.shareThisForlineItemValue = parseFloat(this.inputValueLineItemChange) + parseFloat(this.taxSelectedForLineItem);
      } else {
        this.shareThisForlineItemValue = parseFloat(this.inputValueLineItemChange);
      }
      // console.log(this.shareThisForlineItemValue);

      if (parseFloat(this.inputValueLineItemChange) <= parseFloat(this.selectedLineItemAppeasmentRes.total_refund_amount)) {
        reqbody = {
          "key": "line_level",
          "channel_name": this.itemsData.channel,
          "order_ref_id": this.itemsData.order_ref_id,
          "order_part_no": [this.selectedOrderPartNumberDetails.order_part_no],
          "refund_amount": this.shareThisForlineItemValue,
        }
      } else {
        reqbody = null
      }

    }

    // console.log(reqbody);

    if (reqbody == null) {

    } else {

      this.service.Refundcalculateforsubmit(reqbody).subscribe(res => {
        // console.log(res);

        this.loader = false;
        this.gettransactions = res;

        // console.log(this.gettransactions);
        if (this.gettransactions.message) {
          $('#appeasementTransactionspopupSubmit').attr("data-toggle", "");
          $('#appeasementTransactionspopup').modal('hide');
          $.notify({
            icon: "add_alert",
            message: this.gettransactions.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          $('#appeasementTransactionspopupSubmit').attr("data-toggle", "modal");
          $('#appeasementTransactionspopup').modal('show');
          // console.log(this.gettransactions.transactions);
        }

      }, err => {
        // console.log(err);
        this.loader = false;

      });
    }
  }



  transactionsItemCheck(event, selectedTransaction, i) {
    // console.log(selectedTransaction);
    // console.log(i);
    if (event.target.checked == true) {
      this.selectedTransactionDetails = selectedTransaction;
      this.showThisSelectedTransactions = null;
    } else {
      this.selectedTransactionDetails = null;
    }
    // console.log(this.selectedTransactionDetails);
  }


  SubmitSelectedTransactions() {
    this.loader = true;
    // console.log(this.selectedTransactionDetails);
    var finalSelectedTransactionsArray = [];
    for (var i = 0; i <= this.gettransactions.transactions.length; i++) {
      if ($('#transactionCheckDynamic' + i).prop("checked") === true) {
        finalSelectedTransactionsArray.push(this.gettransactions.transactions[i]);
      }
    }
    // console.log(finalSelectedTransactionsArray);

    if (finalSelectedTransactionsArray.length != 0) {
      // console.log(this.selectedTransactionDetails);
      if (this.selectedForOrderAppeasementType == "LineItem") {
        var requestBody = {
          "key": "line_level",
          "order_no": this.itemsData.order_no,
          "channel_name": this.itemsData.channel,
          "user_email": localStorage.getItem('User_Email'),
          "order_ref_id": this.itemsData.order_ref_id,
          "reason": this.selectedReturenReason,
          "transactions": finalSelectedTransactionsArray,
          "refund_amount": this.shareThisForlineItemValue,
          "total_refund_amount": this.shareThisForlineItemValue,
          "customised_amount": this.inputValueLineItemChange,
          "item_tax": this.selectedLineItemAppeasmentRes.item_tax,
          "order_part_no": [this.selectedOrderPartNumberDetails.order_part_no],
          "custom_updated_price": this.inputValueLineItemChange,
          "is_item_tax": this.itemtaxCheckboxChecked
        }

        // console.log(requestBody);

        this.service.createFinalTranscationSubmit(requestBody).subscribe(res => {
          // console.log(res);
          this.finalresult = res;
          if (this.finalresult.message) {
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
            this.showThisSelectedTransactions = this.finalresult.message;
          } else {
            this.router.navigate(['/orders']);
            $.notify({
              icon: "add_alert",
              message: this.finalresult.success_msg
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "modal");
            $('#appeasementTransactionspopup').modal('hide');
          }
        }, err => {
          // console.log(err);
          this.finalresult = err;
          // this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.finalresult.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
        })
      }

      if (this.selectedForOrderAppeasementType == "ItemTax") {
        var requestbody = {
          "key": "item_tax",
          "order_no": this.itemsData.order_no,
          "channel_name": this.itemsData.channel,
          "user_email": localStorage.getItem('User_Email'),
          "order_ref_id": this.itemsData.order_ref_id,
          "reason": this.selectedReturenReason,
          "transactions": finalSelectedTransactionsArray,
          "refund_amount": this.selectedItemTaxAppeasmentRes.total_refund_amount,
          "total_refund_amount": this.selectedItemTaxAppeasmentRes.total_refund_amount,
          "item_tax": this.selectedItemTaxAppeasmentRes.total_refund_amount,
          "order_part_no": [this.selectedOrderPartNumberDetails.order_part_no],
          "is_item_tax": true
        }

        // console.log(requestbody);

        this.service.createFinalTranscationSubmit(requestbody).subscribe(res => {
          // console.log(res);
          this.finalresult = res;
          this.loader = false;
          if (this.finalresult.message) {
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
            this.showThisSelectedTransactions = this.finalresult.message;
          } else {
            this.router.navigate(['/orders']);
            $.notify({
              icon: "add_alert",
              message: this.finalresult.success_msg
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "modal");
            $('#appeasementTransactionspopup').modal('hide');
          }


        }, err => {
          // console.log(err);
          this.finalresult = err;
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.finalresult.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
        })
      }

      if (this.selectedForOrderAppeasementType == "SandHtax") {
        var reqbody = {
          "key": "s&h_charge",
          "order_no": this.itemsData.order_no,
          "channel_name": this.itemsData.channel,
          "user_email": localStorage.getItem('User_Email'),
          "order_ref_id": this.itemsData.order_ref_id,
          "reason": this.selectedReturenReason,
          "transactions": finalSelectedTransactionsArray,
          "refund_amount": this.selectedSandHAppeasmentRes.total_refund_amount,
          "total_refund_amount": this.selectedSandHAppeasmentRes.total_refund_amount,
          "s&h_charge": this.selectedSandHAppeasmentRes.total_refund_amount,
          "order_part_no": this.getSelectedOrder_part_noForAppeasment,
          "is_sandh_charge": true,
          "shipping_charge": this.selectedSandHAppeasmentRes.shipping_charge,
          "shipping_tax": this.selectedSandHAppeasmentRes.shipping_tax
        }

        // console.log(reqbody);

        this.service.createFinalTranscationSubmit(reqbody).subscribe(res => {
          // console.log(res);
          this.loader = false;
          this.finalresult = res;
          if (this.finalresult.message) {
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
            this.showThisSelectedTransactions = this.finalresult.message;
          } else {
            this.router.navigate(['/orders']);
            $.notify({
              icon: "add_alert",
              message: this.finalresult.success_msg
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "modal");
            $('#appeasementTransactionspopup').modal('hide');
          }
        }, err => {
          this.finalresult = err;
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.finalresult.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
        })
      }

      if (this.selectedForDiscount == "ApplyDiscount") {
        var reqesTbody = {
          "key": "discount",
          "order_no": this.itemsData.order_no,
          "channel_name": this.itemsData.channel,
          "user_email": localStorage.getItem('User_Email'),
          "order_ref_id": this.itemsData.order_ref_id,
          "reason": this.selectedReturenReason,
          "transactions": finalSelectedTransactionsArray,
          "refund_amount": this.applyDiscountResponse.total_refund_amount,
          "total_refund_amount": this.applyDiscountResponse.total_refund_amount,
          "order_part_no_details": this.applyDiscountResponse.order_part_no_details,
          "order_part_no": this.applyDiscountResponse.order_part_no

        }
        // console.log(reqesTbody);
        this.showThisSelectedTransactions = null;
        this.service.createFinalTranscationSubmit(reqesTbody).subscribe(res => {
          // console.log(res);
          this.loader = false;
          this.finalresult = res;
          if (this.finalresult.message) {
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
            this.showThisSelectedTransactions = this.finalresult.message;
          } else {
            this.router.navigate(['/orders']);
            $.notify({
              icon: "add_alert",
              message: this.finalresult.success_msg
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            $('#appeasementTransactionspopupClose').attr("data-dismiss", "modal");
            $('#appeasementTransactionspopup').modal('hide');
          }
        }, err => {
          $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
          this.finalresult = err;
          this.router.navigate(['/orders']);
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.finalresult.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });
      }
    } else {
      $('#appeasementTransactionspopupClose').attr("data-dismiss", "");
      this.showThisSelectedTransactions = "Please select the transaction";
      this.loader = false;
    }


  }

  InitiateReturn() {


    // console.log(this.itemsData);
    // console.log(this.selectedOrderDetails);


    var finalSelectedCheckBoxes = [];
    var finalSelectedCheckBoxesOrder_part_no = [];
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true && this.itemsData.items[i].item_status == "Shipped") {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);
        finalSelectedCheckBoxesOrder_part_no.push(this.itemsData.items[i].order_part_no)
      }
      else {
        // console.log("no");
      }
    }
    // console.log(finalSelectedCheckBoxes);
    // console.log(finalSelectedCheckBoxesOrder_part_no);
    // console.log("reason", finalSelectedCheckBoxesOrder_part_no);

    // -------------------------------------------------------
    // console.log(this.selectedReturnTypeInfo);
    if (this.selectedReturnTypeInfo == "Return & Replacement") {
      this.returnAndreplacementData = true;
      this.returnAndrefundData = false;
    }
    if (this.selectedReturnTypeInfo == "Return & Refund") {
      this.returnAndreplacementData = false;
      this.returnAndrefundData = true;
    }
    if (this.selectedReturnTypeInfo == null || this.selectedReturnTypeInfo == undefined || !this.selectedReturnTypeInfo) {
      this.returnAndreplacementData = undefined;
      this.returnAndrefundData = undefined;
    }
    // console.log(this.returnAndreplacementData);
    // console.log(this.returnAndrefundData);
    // -------------------------------------------------------
    this.reason_line_itemid = this.itemsData.items[0].line_item_id
    var finalSelectedCheckBoxes = [];
    // for line_item_id array looping here
    var checkedLineIDItems = [];
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true && this.itemsData.items[i].item_status == "Shipped") {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);

        checkedLineIDItems.push(
          {
            item_id: this.itemsData.items[i].item_id,
            quantity: this.itemsData.items[i].quantity,
            price: this.itemsData.items[i].price,
            total_price: this.itemsData.items[i].total_price,
            order_part_no: this.itemsData.items[i].order_part_no,
            item_tax: this.itemsData.items[i].item_tax,
            line_item_id: this.itemsData.items[i].line_item_id
          }
        )

      }
      else {
        // console.log("no");
      }
    }




    // for order parts array looping here

    this.orderparts = this.itemsData.items[0].order_part_no
    var finalSelectedCheckBoxes = [];
    var finalSelectedCheckBoxesOrder_order_part_no = [];
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true && this.itemsData.items[i].item_status == "Shipped") {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);
        finalSelectedCheckBoxesOrder_order_part_no.push(this.itemsData.items[i].order_part_no)
      }
      else {
        // console.log("no");
      }
    }
    // console.log(checkedLineIDItems);
    // console.log(finalSelectedCheckBoxesOrder_order_part_no);




    // console.log(this.appeasementResultSelectedCheckbox);
    // console.log(this.showTheEnterCustomeAmount);
    // console.log(this.shopifyPaymentsRefunds);
    if (this.showTheEnterCustomeAmount == true) {
      // console.log(this.inputValue);
      if (this.inputValue == undefined || this.inputValue == null || this.inputValue == '') {
        this.pleaseEnterInputValue = true;
      } else {
        this.pleaseEnterInputValue = false;
      }
    } else {
      this.pleaseEnterInputValue = false;
    }
    this.full_refund_amount = this.allChecked ? true : false;

    // console.log(this.finalSandHValue);

    // console.log(this.shopifyPaymentsRefunds);
    // console.log(this.appeasementResultSelectedCheckbox);



    if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.gc_refund_amount > 0) {

      checkedLineIDItems[0].price = this.shopifygiftCartamountSum;


    }
    if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.gc_refund_amount == 0) {
      checkedLineIDItems[0].price = this.amount;
      this.GCamount = 0;


    }

    /*Only Gift card payment code */
    if (this.appeasementResultSelectedCheckbox.is_customizable == true && this.appeasementResultSelectedCheckbox.max_refund_amount == 0) {
      // checkedLineIDItems[0].price = this.GCamount;

      checkedLineIDItems[0].price = this.GCamountForPrice;

      this.shopifyPaymentsRefunds = 0;

    }
    var fullRedundInfo = this.appeasementResultSelectedCheckbox.full_refund;
    if (this.appeasementResultSelectedCheckbox.is_customizable == false) {

      this.GCamount = 0;
      if (this.sandhCheckBoxChecked == true) {
        fullRedundInfo = true;
      } else {
        fullRedundInfo = false;
      }
    }
    // console.log(fullRedundInfo);

    // console.log(this.shopifygiftCartamountSum);
    // console.log(this.GCamount);
    // console.log(this.taxAmount);

    if (this.appeasementResultSelectedCheckbox.is_customizable == true) {
      checkedLineIDItems[0].item_tax = this.taxAmount;
    }
    // console.log(checkedLineIDItems);

    var requestBody = {
      "order_no": this.itemsData.order_no,
      "items": checkedLineIDItems,
      "reason": this.selectedReturenReason,
      "channel_name": this.itemsData.channel,
      "refund_amount": this.shopifyPaymentsRefunds,
      // "gcrefund_amount": this.shopifygiftCartamountSum,
      "gcrefund_amount": this.GCamount,
      "order_parts": finalSelectedCheckBoxesOrder_order_part_no,
      "user_email": localStorage.getItem('User_Email'),
      "full_refund": fullRedundInfo,
      "include_shipments": this.finalSandHValue
    }
    // console.log('ans', finalSelectedCheckBoxes)
    // console.log(requestBody);
    if (this.showcasetheonlyGCvalueblock == true) {
      if (requestBody.channel_name == undefined || requestBody.full_refund == undefined || requestBody.gcrefund_amount == undefined || requestBody.order_no == undefined || requestBody.reason == undefined || requestBody.refund_amount == undefined || requestBody.user_email == undefined || requestBody.items.length == 0 || requestBody.order_parts.length == 0 || requestBody.refund_amount == null) {


        // console.log(requestBody);
        // console.log("please select the values");
        $.notify({
          icon: "add_alert",
          message: "Please select all"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        // console.log("all selected");

        // console.log(requestBody);
        this.loader = true;

        this.service.appeasementrefund(requestBody).subscribe(res => {
          this.appeasement_maximum_amount = res;
          this.router.navigate(['/orders']);
          if (this.appeasement_maximum_amount.error_msg) {

            const errmsg = this.appeasement_maximum_amount;
            const objectmsg = Object.keys(errmsg).map(key => errmsg[key])[0];
            // console.log(objectmsg);

            $.notify({
              icon: "add_alert",
              message: objectmsg,
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.loader = false;
          }
          this.loader = false;
          $('#shopifyPaymentsRefunds').val("");
          $('#gcmaximum_refundable').val("");

          $('#reason_filter').val("");

          // console.log(res);
          this.gcresult = res;
          // console.log(this.gcresult.status);


          $.notify({
            icon: "add_alert",
            message: this.gcresult.status
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });

        }, err => {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            // message: res.error_msg.errors.refund_line_items.quantity
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });

      }
    } else {

      if (requestBody.channel_name == undefined || requestBody.full_refund == undefined || requestBody.gcrefund_amount == undefined || requestBody.order_no == undefined || requestBody.reason == undefined || requestBody.refund_amount == undefined || requestBody.user_email == undefined || requestBody.items.length == 0 || requestBody.order_parts.length == 0 || requestBody.refund_amount == 0 || requestBody.refund_amount == null || this.pleaseEnterInputValue == true) {


        // console.log(requestBody);
        // console.log("please select the values");
        $.notify({
          icon: "add_alert",
          message: "Please select all"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        // console.log("all selected");

        // console.log(requestBody);
        this.loader = true;

        this.service.appeasementrefund(requestBody).subscribe(res => {
          this.appeasement_maximum_amount = res;
          this.router.navigate(['/orders']);
          if (this.appeasement_maximum_amount.error_msg) {

            const errmsg = this.appeasement_maximum_amount;
            const objectmsg = Object.keys(errmsg).map(key => errmsg[key])[0];
            // console.log(objectmsg);

            $.notify({
              icon: "add_alert",
              message: objectmsg,
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.loader = false;
          }
          this.loader = false;
          $('#shopifyPaymentsRefunds').val("");
          $('#gcmaximum_refundable').val("");

          $('#reason_filter').val("");

          // console.log(res);
          this.gcresult = res;
          // console.log(this.gcresult.status);


          $.notify({
            icon: "add_alert",
            message: this.gcresult.status
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });

        }, err => {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            // message: res.error_msg.errors.refund_line_items.quantity
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });

      }
    }






  }








}
